import { createRoot } from 'react-dom/client';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { Provider } from 'react-redux';
import { matchPath } from 'react-router';
import { Elements } from '@stripe/react-stripe-js';
import { fetchAuthSession, getCurrentUser, signOut } from 'aws-amplify/auth';
import { ConnectedRouter } from 'connected-react-router';
import _ from 'lodash';
import queryString from 'query-string';
import Store from 'store';

import { configureAmplify, LAUNCH_DARKLEY_KEY } from '@pumpkincare/config';
import { patchIdentity } from '@pumpkincare/identity';
import {
  determineMarketingChannelPayload,
  setMarketingAttribution,
} from '@pumpkincare/marketing';
import {
  getQuoteById,
  getQuoteIdentityId,
  getQuoteTrackingId,
  QUOTE_ID_COOKIE_NAME,
  QUOTE_QUERY,
  QuotesProvider,
  transformQuoteRedux,
} from '@pumpkincare/quotes';
import {
  BannersProvider,
  captureException,
  getCookie,
  getIsLoggedIn,
  IDENTITY_LOCAL_STORAGE_KEY,
  matchScreenSizes,
  QUOTE_URL_PARAMS_KEY,
  setCookie,
  setIsLoggedIn,
} from '@pumpkincare/shared';
import { GlobalStylesInject } from '@pumpkincare/shared/ui';
import { getUserQueryFn, USER_SELF_QUERY } from '@pumpkincare/user';

import { setQuoteResponseV2 } from '../quotes';
import { getQuotes } from '../quotes/selectors';
import { setMediaMatches } from './state/app-ducks';
import { createQuoteFlowStore, history } from './state/redux-store';
import formatLaunchDarklyUser from './utils/format-launch-darkly-user';
import App from './view/app';
import { configureApp, initLaunchDarkly, initStripe } from './config.js';
import Paths from './paths';

export default function AppController() {
  const store = createQuoteFlowStore();
  const dispatch = store.dispatch;
  const getState = store.getState;

  const { quoteId: searchQuoteId, identityId: searchIdentityId } = queryString.parse(
    window.location.search
  );
  const { params } =
    matchPath(window.location.pathname, {
      path: [Paths.PlanSelectionDeepLink, Paths.CheckoutDeepLink],
      exact: true,
    }) || {};

  const quoteIdFromURL = searchQuoteId || params?.quoteId;

  if (searchIdentityId) Store.set(IDENTITY_LOCAL_STORAGE_KEY, searchIdentityId);
  const initIdentityId = Store.get(IDENTITY_LOCAL_STORAGE_KEY);

  if (quoteIdFromURL) setCookie(QUOTE_ID_COOKIE_NAME, quoteIdFromURL);

  const quoteId = quoteIdFromURL || getCookie(QUOTE_ID_COOKIE_NAME);

  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        onError: captureException,
        refetchOnWindowFocus: false,
        retry: 0,
      },
    },
  });

  // bootstrap needs to happen before initLaunchDarkly for parseQueryString
  bootstrap();
  authenticateUser();

  /*
   LD experiment for wp86 should be setup such that if user has wp86:off in country, they are not part of the 50/50 rollout
   using country bc they don't support custom attributes
   */
  function setHeyflowRolloutIgnoreAttribute() {
    const isDiscountFlow = !!matchPath(window.location.pathname, {
      path: [Paths.Teams],
      exact: true,
    });
    const isLoggedIn = getIsLoggedIn();
    const hasUtmSource = !!Store.get(QUOTE_URL_PARAMS_KEY)?.utm_source;

    return isDiscountFlow || isLoggedIn || hasUtmSource || quoteId ? 'wp86:off' : '';
  }

  const heyflowRolloutIgnoreAttribute = setHeyflowRolloutIgnoreAttribute();

  const { withLaunchDarkly, ldClient } = initLaunchDarkly(
    LAUNCH_DARKLEY_KEY,
    formatLaunchDarklyUser(
      Store.get(IDENTITY_LOCAL_STORAGE_KEY),
      heyflowRolloutIgnoreAttribute
    )
  );

  const EnhancedApp = withLaunchDarkly(App);

  addListeners();
  render();

  function addListeners() {
    Store.observe(IDENTITY_LOCAL_STORAGE_KEY, value => {
      if (value !== initIdentityId) {
        ldClient.identify(
          formatLaunchDarklyUser(value, heyflowRolloutIgnoreAttribute)
        );
      }
    });

    // todo dont dispatch on every resize, only if breakpoints change
    window.addEventListener(
      'resize',
      _.debounce(() => {
        dispatch(setMediaMatches(matchScreenSizes()));
      }, 100)
    );
  }

  function onQuoteSuccess(quote) {
    // Transform the quote using the current state
    const transformedQuote = transformQuoteRedux(quote, getQuotes(getState()));

    // Set the transformed quote response
    dispatch(setQuoteResponseV2(transformedQuote));

    const identityId = getQuoteIdentityId(transformedQuote);
    const trackingId = getQuoteTrackingId(transformedQuote);

    // Store identityId in local storage
    Store.set(IDENTITY_LOCAL_STORAGE_KEY, identityId);
    patchIdentity({ id: identityId, tracking_id: trackingId });
    return quote;
  }

  function bootstrap() {
    if (quoteId) {
      queryClient.prefetchQuery([QUOTE_QUERY, quoteId], () =>
        getQuoteById({ quoteId, onSuccess: onQuoteSuccess })
      );
    }

    parseQueryString();

    dispatch(setMediaMatches(matchScreenSizes()));

    configureApp(store);
  }

  function parseQueryString() {
    const values = queryString.parse(window.location.search);

    if (Object.keys(values).length) {
      setMarketingAttribution(determineMarketingChannelPayload(values));

      Store.set(QUOTE_URL_PARAMS_KEY, values);
    }
  }

  function authenticateUser() {
    configureAmplify();

    getCurrentUser()
      .then(() => {
        return fetchAuthSession();
      })
      .then(() => {
        setIsLoggedIn(true);

        queryClient.prefetchQuery([USER_SELF_QUERY], () => getUserQueryFn());
      })
      .catch(() => {
        signOut();
        setIsLoggedIn(false);
      });
  }

  function render() {
    const container = document.getElementById('root');
    const root = createRoot(container);

    root.render(
      <Provider store={store}>
        <ConnectedRouter history={history}>
          <Elements
            stripe={initStripe()}
            options={{
              fonts: [
                {
                  // load custom font to Stripe iFrame
                  cssSrc: 'https://fonts.googleapis.com/css?family=Nunito+Sans',
                },
              ],
            }}
          >
            <QueryClientProvider client={queryClient}>
              <ReactQueryDevtools initialIsOpen={false} />

              <BannersProvider>
                <QuotesProvider>
                  <GlobalStylesInject />
                  <EnhancedApp />
                </QuotesProvider>
              </BannersProvider>
            </QueryClientProvider>
          </Elements>
        </ConnectedRouter>
      </Provider>
    );
  }
}
