import { useState } from 'react';
import classnames from 'classnames';

import { ButtonStyles, ChevronLeft, Link, Typography } from '@pumpkincare/shared/ui';

import styles from './dropdown-nav.css';

function DropDownNav({ label = 'Jump To', items = [], trackNavigation = () => {} }) {
  const [isOpen, setIsOpen] = useState(false);

  function handleOpenClick() {
    setIsOpen(!isOpen);
  }

  function handleMenuItemClickFactory(label, callback) {
    return function handleMenuItemClick() {
      callback?.();
      trackNavigation(label);
      setIsOpen(false);
    };
  }

  return (
    <div className={styles.root}>
      <button
        className={classnames(ButtonStyles.baseButton, styles.openCloseButton)}
        onClick={handleOpenClick}
      >
        {label} <ChevronLeft transform={isOpen ? 'rotate(90)' : 'rotate(-90)'} />
      </button>

      <ul
        className={classnames(styles.menuItems, { [styles.hidden]: !isOpen })}
        role='menu'
      >
        {items.map(({ label, href, callback }) => {
          return (
            <li key={label}>
              <Link
                className={Typography.body2}
                href={href}
                onClick={handleMenuItemClickFactory(label, callback)}
              >
                {label}
              </Link>
            </li>
          );
        })}
      </ul>
    </div>
  );
}

export default DropDownNav;
