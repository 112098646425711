import classNames from 'classnames';

import { getPlanWebDescription, parsePlanDetails } from '@pumpkincare/plans';
import { sortLegalData } from '@pumpkincare/quotes';
import { Body1, Body2, Superscript2, Typography } from '@pumpkincare/shared/ui';

import ConfirmPepButtons from '../confirm-pep-buttons';

import styles from './pep4-picker-details.module.css';

function Pep4PickerDetails({ plan, activePet, onAdd, onRemove }) {
  const planDetails = parsePlanDetails(plan, activePet?.petBreedSpecies);

  const webDescription = plan ? getPlanWebDescription(plan) : '';
  const { legal } = webDescription;
  const sortedLegal = legal ? sortLegalData(legal) : [];

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <h4>{activePet.petName}’s Preventive Essentials Pack</h4>
        <Body2 className={styles.subTitle}>Designed by {plan?.vet_name}</Body2>
      </div>
      <div className={styles.body}>
        <div className={styles.planContent}>
          <div className={styles.planContentHeader}>
            <Body2 className={styles.planContentSubTitle}>
              {plan.savings ? (
                <>
                  You may save over{' '}
                  <span className={styles.savings}>${plan.savings}/yr</span> on this
                  package
                  <Superscript2>*</Superscript2>
                </>
              ) : (
                <> For just ${parseFloat(plan.cost).toFixed(2)} a month</>
              )}
            </Body2>
            <h4 className={classNames(styles.planContentTitle, Typography.h4)}>
              Get a full refund for these key wellness services covered by Preventive
              Essentials:
            </h4>
          </div>
          <div className={styles.gridContents}>
            {plan?.entitlements.map(item => (
              <div key={item.id} className={styles.planItem}>
                <Body1 isBold className={styles.planItemTitle}>
                  {item.category}
                </Body1>
                {planDetails
                  .filter(detail => detail.id === item.id)
                  .map((detail, index) => (
                    <div className={styles.entitlementsContainer} key={index}>
                      <Body1 isBold className={styles.planItemIcon}>
                        &#43;
                      </Body1>
                      <Body2>{detail.item}</Body2>
                    </div>
                  ))}
              </div>
            ))}
          </div>
        </div>
        <img
          className={classNames(styles.imageContainer, styles.bottomImage)}
          src='/assets/images/hdyhau/pep-large.png'
          alt='Already had their vaccines? It’s not too late to get a refund! Enroll in Preventive Essentials within 14 days to get a bowlful back on these wellness services.'
        />
      </div>

      <div>
        <ConfirmPepButtons
          onAdd={onAdd}
          onRemove={onRemove}
          classes={{ container: styles.flexContentCenter }}
        />
      </div>

      {sortedLegal.map((item, index) => {
        return (
          <div key={index} className={styles.legalText}>
            <b dangerouslySetInnerHTML={{ __html: item.title }} />{' '}
            <span dangerouslySetInnerHTML={{ __html: item.content }} />
          </div>
        );
      })}
    </div>
  );
}

export default Pep4PickerDetails;
