// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_ICSS_IMPORT_0___ from "-!../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[2].use[1]!../../../../libs/shared/src/ui/breakpoints.module.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".teams_mainContainer_1ab37f5b {\n  background-color: var(--cujoCream);\n\n  display: flex;\n  flex-wrap: wrap;\n  flex-direction: row;\n  padding: 0 var(--w1);\n}\n\n.teams_contentBlock_807b9415 {\n  z-index: 5;\n  margin: 24px 0 96px 0;\n}\n\n.teams_headerH1_8594c5f2 {\n  margin-bottom: 16px;\n}\n\n.teams_headerBody1_88395617 {\n  margin-bottom: 16px;\n}\n\n.teams_headerMessage_5ece1f1b {\n  margin-bottom: 16px;\n}\n\n.teams_headerButton_cb22a291 {\n  color: var(--scoutNavy);\n  text-decoration: none;\n  margin-bottom: 24px;\n  width: 100%;\n}\n\n.teams_textField_77d0730f {\n  width: 100%;\n  padding-bottom: 16px;\n}\n\n@media " + ___CSS_LOADER_ICSS_IMPORT_0___.locals["tablet"] + " {\n  .teams_contentBlock_807b9415 {\n    width: var(--w7);\n  }\n}", "",{"version":3,"sources":["webpack://src/teams/teams.css"],"names":[],"mappings":"AAEA;EACE,kCAAkC;;EAElC,aAAa;EACb,eAAe;EACf,mBAAmB;EACnB,oBAAoB;AACtB;;AAEA;EACE,UAAU;EACV,qBAAqB;AACvB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,uBAAuB;EACvB,qBAAqB;EACrB,mBAAmB;EACnB,WAAW;AACb;;AAEA;EACE,WAAW;EACX,oBAAoB;AACtB;;AAEA;EACE;IACE,gBAAgB;EAClB;AACF","sourcesContent":["@value tablet from '~@pumpkincare/shared/ui/breakpoints';\n\n.mainContainer {\n  background-color: var(--cujoCream);\n\n  display: flex;\n  flex-wrap: wrap;\n  flex-direction: row;\n  padding: 0 var(--w1);\n}\n\n.contentBlock {\n  z-index: 5;\n  margin: 24px 0 96px 0;\n}\n\n.headerH1 {\n  margin-bottom: 16px;\n}\n\n.headerBody1 {\n  margin-bottom: 16px;\n}\n\n.headerMessage {\n  margin-bottom: 16px;\n}\n\n.headerButton {\n  color: var(--scoutNavy);\n  text-decoration: none;\n  margin-bottom: 24px;\n  width: 100%;\n}\n\n.textField {\n  width: 100%;\n  padding-bottom: 16px;\n}\n\n@media tablet {\n  .contentBlock {\n    width: var(--w7);\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tablet": "" + ___CSS_LOADER_ICSS_IMPORT_0___.locals["tablet"] + "",
	"mainContainer": "teams_mainContainer_1ab37f5b",
	"contentBlock": "teams_contentBlock_807b9415",
	"headerH1": "teams_headerH1_8594c5f2",
	"headerBody1": "teams_headerBody1_88395617",
	"headerMessage": "teams_headerMessage_5ece1f1b",
	"headerButton": "teams_headerButton_cb22a291",
	"textField": "teams_textField_77d0730f"
};
export default ___CSS_LOADER_EXPORT___;
