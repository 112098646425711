import Store from 'store';

import { usePetPlanData } from '@pumpkincare/plans';
import {
  getQuoteActivePet,
  getQuoteIdentityId,
  getQuoteVetId,
  useQuote,
  useQuotesActiveId,
} from '@pumpkincare/quotes';
import { IDENTITY_LOCAL_STORAGE_KEY } from '@pumpkincare/shared';

import { PlanSelection } from '../../../plans';
import Page from '../page';

function PlanPage() {
  const { activeId } = useQuotesActiveId();

  const { data: quoteData } = useQuote();
  const identityId =
    getQuoteIdentityId(quoteData) || Store.get(IDENTITY_LOCAL_STORAGE_KEY);
  const activePet = getQuoteActivePet(quoteData)(activeId);
  const vetId = getQuoteVetId(quoteData);
  const { isReady } = usePetPlanData({ petId: activePet?.id, identityId, vetId });

  return (
    <Page isNavBarVisible={isReady}>
      <PlanSelection />
    </Page>
  );
}

export default PlanPage;
