import { useMutation, useQueryClient } from 'react-query';

import { analyticsIdentify } from '@pumpkincare/analytics';
import {
  addPetToQuote,
  fetchQuote,
  patchQuotePet,
  QUOTE_ID_COOKIE_NAME,
  QUOTE_QUERY,
  useQuotesActiveId,
  useQuotesPetOrder,
} from '@pumpkincare/quotes';
import {
  captureException,
  GENERIC_ERROR,
  getCookie,
  getIsLoggedIn,
  setCookie,
  useBanners,
} from '@pumpkincare/shared';

function useMutateCreateQuote() {
  const isLoggedIn = getIsLoggedIn();

  return useMutation(({ identityId, trackingId }) =>
    fetchQuote(trackingId, identityId, isLoggedIn).then(resp => resp.id)
  );
}

function useMutateAddPetToQuote() {
  return useMutation(({ quoteId, pet }) => addPetToQuote({ quoteId, pet }));
}

function useMutateUpdatePetInQuote() {
  return useMutation(({ quoteId, pet }) => patchQuotePet({ quoteId, pet }));
}

export default function useSubmitIntroduction() {
  const queryClient = useQueryClient();

  const { isLoading: isCreating, mutateAsync: createQuote } = useMutateCreateQuote();
  const { isLoading: isAddingPet, mutateAsync: addPet } = useMutateAddPetToQuote();
  const { isLoading: isUpdatingPet, mutateAsync: updatePet } =
    useMutateUpdatePetInQuote();

  const { addBanner, removeAllBanners } = useBanners();
  const { setActiveId } = useQuotesActiveId();
  const { petOrder } = useQuotesPetOrder();

  const isSubmitting = [isCreating, isAddingPet, isUpdatingPet].some(Boolean);

  /*
   * Submit introduction form
   * First get a quoteId - it'll either already exist in cookies or we need to create a fresh quote
   * Second, make pet changes to the quote - either add a new pet or update the existing one
   * Then do success actions - invalidate the quote cache so that quote query will run (and do all the transforms) and set the active pet id for the next pages in the quote flow
   */
  function submitIntroduction({ identityId, trackingId, pet }) {
    removeAllBanners();

    return Promise.resolve(
      getCookie(QUOTE_ID_COOKIE_NAME) || createQuote({ identityId, trackingId })
    )
      .then(quoteId => {
        setCookie(QUOTE_ID_COOKIE_NAME, quoteId);

        const submitFn = typeof pet.id === 'number' ? addPet : updatePet; // interim pet id, before pet is added to DB
        return submitFn({ quoteId, pet });
      })
      .then(responsePet => {
        queryClient.invalidateQueries([
          QUOTE_QUERY,
          getCookie(QUOTE_ID_COOKIE_NAME),
        ]);

        const idx = petOrder[responsePet.id] || 1;
        analyticsIdentify({
          [`PET${idx}NAME`]: responsePet.petName,
          [`PET${idx}SPECIES`]: responsePet.petBreedSpecies,
        });

        setActiveId(responsePet.id);
      })
      .catch(err => {
        captureException(err);

        addBanner(GENERIC_ERROR);
      });
  }

  return { isSubmitting, submitIntroduction };
}
