import classNames from 'classnames';

import { isUnlimitedLimit } from '@pumpkincare/plans';
import { getFullPercentage } from '@pumpkincare/shared';
import { Body2, LegalBody, Superscript1 } from '@pumpkincare/shared/ui';

import InsuranceModal from '../insurance-modal';

import styles from './levers.module.css';

import petsCheck from './pets-check-list.png';

const LEVERS_TO_TITLES_MAP = {
  limits: 'Annual Limit',
  copays: 'Reimbursement',
  deductibles: 'Annual Deductible',
};

const leversObj = {
  limits: {
    title: 'Annual Coverage Limit',
    sectionItems: [
      {
        title: 'What is it?',
        copy: (
          <>
            The max amount of eligible vet bills your pet insurance plan can
            reimburse you for each policy year.
          </>
        ),
      },
      {
        title: 'Which do I choose?',
        copy: (
          <>
            While a $10k annual limit is high enough for 98% of recent pupstomers and
            a $7k limit is high enough for 97% of recent catstomers, we offer higher
            options if you're worried about expensive vet bills or want extra peace
            of mind.{' '}
            <Superscript1
              role='button'
              className={styles.supScript}
              onClick={() =>
                window.scrollTo({
                  top: document.body.scrollHeight,
                  behavior: 'smooth',
                })
              }
            >
              1
            </Superscript1>
          </>
        ),
      },
    ],
    footer: {
      items: [
        {
          title: 'Tip!',
          copy: 'You can’t increase your annual limit after you set it, so choose carefully!',
        },
      ],
      img: '/assets/images/iconImages/pets-saving.png',
    },
  },

  copays: {
    title: 'Reimbursement Rate',
    sectionItems: [
      {
        title: 'What is it?',
        copy: (
          <>
            This is the percentage of an eligible vet bill your pet insurance plan
            will reimburse you for.
          </>
        ),
      },
      {
        title: 'Which do I choose?',
        copy: (
          <>
            We offer two high reimbursement rate options – 80% or 90%. Choose 90% if
            you want the most cash back for eligible vet bills. Choose 80% if you
            want a substantial amount of cash back, but also are looking to spend
            less on insurance payments.
          </>
        ),
      },
    ],
    footer: {
      items: [
        {
          title: 'Tip!',
          copy: 'Your 80% or 90% cash back on eligible vet bills kicks in after your annual deductible.',
        },
      ],
      img: '/assets/images/iconImages/pets-saving.png',
    },
  },

  deductibles: {
    title: 'Annual Deductible',
    sectionItems: [
      {
        title: 'What is it?',
        copy: (
          <>
            The amount you pay upfront towards eligible vet bills each policy year
            <b> before</b> your cash back kicks in.
          </>
        ),
      },
      {
        title: 'Which do I choose?',
        copy: (
          <>
            Choose a $500 or $1k annual deductible if you’re worried about high vet
            bills or multiple accidents & illnesses. If neither are a concern, $100
            or $250 may be right for you.
          </>
        ),
      },
    ],
    footer: {
      img: petsCheck,
      items: [
        {
          title: 'Tip!',
          copy: 'The higher the annual deductible, the lower your monthly cost!',
        },
      ],
    },
  },
};

export function formatLevers(levers, leverOrder) {
  return leverOrder
    .map(key => {
      const items = levers[key];
      if (!items) return null;
      if (key === 'copays') items.sort(a => (a.option.value === '20' ? -1 : 1));
      return { key, items };
    })
    .filter(Boolean);
}

export function getDisplayText(item) {
  switch (item.option.key) {
    case 'annual_limit':
      return isUnlimitedLimit(item.option.value)
        ? item.option.value
        : `$${item.option.value / 1000}k`;
    case 'deductible':
      return `$${Number(item.option.value).toLocaleString('en')}`;
    case 'copay':
      return getFullPercentage(item.option.value);
    default:
      return '';
  }
}
function Levers({ activeValues = {}, levers = {}, onChange, isActive = false }) {
  const leverOrder = ['limits', 'copays', 'deductibles'];

  function handleOnChangeValue(values, e) {
    const { key, value } = values;
    const currentValues = {
      annual_limit: activeValues.annual_limit,
      copay: activeValues.copay,
      deductible: activeValues.deductible,
    };

    const changedValues = { ...currentValues, [key]: value };

    onChange(changedValues, e);
  }

  const leverClassName = classNames(styles.lever, {
    [styles.leverInactive]: !isActive,
  });

  const sortedLevers = formatLevers(levers, leverOrder);

  return (
    <>
      {sortedLevers.map(({ key, items }) => (
        <div key={key} className={styles.container}>
          <div
            className={styles.leverRecommendation}
            data-testid='lever-recommendation'
          >
            <div className={styles.titleWrapper}>
              <Body2 className={styles.leverTitle}>
                {LEVERS_TO_TITLES_MAP[key]}
              </Body2>

              <InsuranceModal
                modalData={leversObj[key] ?? {}}
                classes={{
                  modalTitle: styles.modalTitle,
                  modalIcon: styles.modalIcon,
                }}
              />
            </div>
          </div>

          <div className={styles.levers}>
            {items.map(item => {
              const isSelected = activeValues[item.option.key] === item.option.value;
              const displayText = getDisplayText(item);
              return (
                <button
                  key={item.title}
                  className={leverClassName}
                  aria-pressed={isSelected}
                  onClick={e => handleOnChangeValue(item.option, e)}
                >
                  <LegalBody isBold>{displayText}</LegalBody>
                </button>
              );
            })}
          </div>
        </div>
      ))}
    </>
  );
}

export default Levers;
