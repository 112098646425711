import { useCallback } from 'react';
import classNames from 'classnames';

import { getQuoteEmail, useQuote } from '@pumpkincare/quotes';
import { getIsLoggedIn } from '@pumpkincare/shared';
import {
  Body1,
  Body2,
  InputStyles,
  LegalBody,
  Typography,
} from '@pumpkincare/shared/ui';
import { getUserBillingAddress, useUserAddresses, useUssr } from '@pumpkincare/user';

import styles from './shipping-address-form.css';

function ShippingAddressForm({ address, onChange }) {
  const isLoggedIn = getIsLoggedIn();

  const { data: quoteData } = useQuote();
  const quoteEmail = getQuoteEmail(quoteData);

  const { data: userAddressData } = useUserAddresses({ enabled: isLoggedIn });
  const userBillingAddress = getUserBillingAddress(userAddressData);
  const { data: userData } = useUssr();

  const { firstName = '', lastName = '' } = userData;

  const {
    street_1: street1 = '',
    street_2: street2 = '',
    city = '',
    state_province: stateProvince = '',
    zipcode = '',
  } = userBillingAddress;

  const textInputClassName = classNames(
    styles.textInput,
    styles.border,
    Typography.body2,
    InputStyles.text
  );

  const textInputLockedClassName = classNames(
    styles.textInput,
    styles.borderless,
    Typography.body2,
    InputStyles.text
  );

  function handleFieldChangeFactory(field) {
    return e => {
      return onChange({ field, value: e.target.value.replace(/\s+/g, ' ').trim() });
    };
  }
  /*
   * We had to implement these methods below due to a problem caused by
   * the autocomplete function in the credit card number field,
   * where every time this function is activated, it deletes the information in the First Name field.
   * These functions prevent that, while the first name field is not in focus,
   * it will remain readonly and cannot be changed by third parties
   * https://petlife.atlassian.net/browse/PUNKS-988
   * */

  const handleOnFocus = useCallback(e => {
    e.target.readOnly = false;
  }, []);

  const handleBlur = useCallback(e => {
    e.target.readOnly = true;
  }, []);

  return (
    <div className={styles.shippingForm}>
      <LegalBody style={{ margin: '16px 0 0' }}>
        Enter your pet’s full address. The name, state & zip code from your plan have
        already been added. All fields required unless otherwise noted.
      </LegalBody>

      {isLoggedIn ? (
        <div style={{ margin: '16px 0 0' }}>
          <Body2>
            {firstName} {lastName}
          </Body2>

          <Body2>
            {street1} {street2} {city}, {stateProvince} {zipcode}
          </Body2>
        </div>
      ) : (
        <>
          <div data-testid='shipping-form'>
            <Body1 className={styles.inputLabel}>{address.firstName.label}</Body1>

            <input
              readOnly
              onFocus={handleOnFocus}
              onBlur={handleBlur}
              aria-label={address.firstName.label}
              autoComplete={'given-name'}
              className={classNames(
                textInputClassName,
                address.firstName.error ? styles.error : null
              )}
              // https://stackoverflow.com/questions/64548202/react-input-change-not-firing-when-select-all-to-delete
              // https://petlife.atlassian.net/browse/PUNKS-888
              defaultValue={address.firstName.value}
              id={'firstName'}
              name={'firstName'}
              onChange={handleFieldChangeFactory(address.firstName.id)}
            />

            <Body1 className={styles.inputLabel}>{address.lastName.label}</Body1>

            <input
              autoComplete={'family-name'}
              className={classNames(
                textInputClassName,
                address.lastName.error ? styles.error : null
              )}
              // https://stackoverflow.com/questions/64548202/react-input-change-not-firing-when-select-all-to-delete
              // https://petlife.atlassian.net/browse/PUNKS-888
              defaultValue={address.lastName.value}
              aria-label={address.lastName.label}
              id={'lastName'}
              name={'lastName'}
              onChange={handleFieldChangeFactory(address.lastName.id)}
            />

            <Body1 className={styles.inputLabel}>Address</Body1>

            <input
              aria-label={'Address'}
              autoComplete={'address-line1'}
              className={classNames(
                textInputClassName,
                address.address1.error ? styles.error : null
              )}
              data-testid={'address1-input'}
              defaultValue={address.address1.value}
              id={'address1'}
              name={'address1'}
              onChange={handleFieldChangeFactory(address.address1.id)}
            />

            <Body1 className={styles.inputLabel}>Address Line 2 (Optional)</Body1>

            <input
              aria-label={'Address line 2'}
              autoComplete={'address-line2'}
              className={textInputClassName}
              data-testid={'address2-input'}
              defaultValue={address.address2.value}
              id={'address2'}
              name={'address2'}
              onChange={handleFieldChangeFactory(address.address2.id)}
            />

            <Body1 className={styles.inputLabel}>City</Body1>

            <input
              aria-label={'City'}
              autoComplete={'address-level2'}
              className={classNames(
                textInputClassName,
                address.city.error ? styles.error : null
              )}
              data-testid={'city-input'}
              defaultValue={address.city.value}
              id={'city'}
              name={'city'}
              onChange={handleFieldChangeFactory(address.city.id)}
            />

            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
              }}
            >
              <div className={styles.divColumnLeft}>
                <Body1 className={styles.inputLabel}>State</Body1>

                <input
                  aria-label={'State'}
                  autoComplete={'address-level1'}
                  className={textInputLockedClassName}
                  data-testid={'state-input'}
                  defaultValue={address.state.value}
                  disabled
                  id={'state'}
                  name={'state'}
                  onChange={handleFieldChangeFactory(address.state.id)}
                />

                <img
                  alt=''
                  className={styles.lockIcon}
                  src={'/assets/images/iconImages/padlock.png'}
                />
              </div>

              <div className={styles.divColumnRight}>
                <Body1 className={styles.inputLabel}>Zip Code</Body1>

                <input
                  aria-label={'Zip'}
                  autoComplete={'postal-code'}
                  className={textInputLockedClassName}
                  data-testid={'zipCode-input'}
                  defaultValue={address.zipcode.value}
                  disabled
                  id={'zipcode'}
                  name={'zipcode'}
                  onChange={handleFieldChangeFactory(address.zipcode.id)}
                />

                <img
                  alt=''
                  className={styles.lockIcon}
                  src={'/assets/images/iconImages/padlock.png'}
                />
              </div>
            </div>

            <div style={{ position: 'relative' }}>
              <Body1 className={styles.inputLabel}>Email Address</Body1>

              <input
                aria-label={'Email'}
                className={textInputLockedClassName}
                data-testid={'email-input'}
                defaultValue={quoteEmail}
                disabled
              />

              <img
                alt=''
                className={styles.lockIcon}
                src={'/assets/images/iconImages/padlock.png'}
              />
            </div>
          </div>

          <Body1 className={styles.inputLabel}>Phone number</Body1>

          <input
            aria-label='Phone'
            autoComplete={'tel'}
            className={classNames(
              styles.textInput,
              Typography.body2,
              styles.border,
              InputStyles.textBorderless,
              address.phone.error ? styles.error : null
            )}
            data-testid='phone-input'
            defaultValue={address.phone.value}
            id={'phone'}
            name={'phone'}
            onChange={handleFieldChangeFactory(address.phone.id)}
          />
        </>
      )}
    </div>
  );
}

export default ShippingAddressForm;
