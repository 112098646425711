// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_ICSS_IMPORT_0___ from "-!../../../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[2].use[1]!../../../../../../libs/shared/src/ui/breakpoints.module.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".confirm-pep-buttons_container_8d78b582 {\n  display: flex;\n  width: 100%;\n  justify-content: center;\n}\n\n.confirm-pep-buttons_addButton_b08171e5 {\n  margin: 0 0 0 8px;\n}\n\n.confirm-pep-buttons_declineButton_931225dc {\n  margin: 0 8px 0 0;\n}\n\n.confirm-pep-buttons_declineButton_931225dc,\n.confirm-pep-buttons_addButton_b08171e5 {\n  width: 50%;\n  max-width: 248px;\n}\n\n@media " + ___CSS_LOADER_ICSS_IMPORT_0___.locals["tablet"] + " {\n  .confirm-pep-buttons_container_8d78b582 {\n    width: 64%;\n    justify-content: end;\n    margin: auto;\n  }\n}\n\n@media " + ___CSS_LOADER_ICSS_IMPORT_0___.locals["desktop"] + " {\n  .confirm-pep-buttons_container_8d78b582 {\n    width: 70%;\n    justify-content: end;\n  }\n\n  .confirm-pep-buttons_addButton_b08171e5 {\n    margin: 0 0 0 8px;\n  }\n\n  .confirm-pep-buttons_declineButton_931225dc {\n    margin: 0 8px 0 0;\n  }\n}", "",{"version":3,"sources":["webpack://src/plans/view/confirm-pep-buttons/confirm-pep-buttons.module.css"],"names":[],"mappings":"AAEA;EACE,aAAa;EACb,WAAW;EACX,uBAAuB;AACzB;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,iBAAiB;AACnB;;AAEA;;EAEE,UAAU;EACV,gBAAgB;AAClB;;AAEA;EACE;IACE,UAAU;IACV,oBAAoB;IACpB,YAAY;EACd;AACF;;AAEA;EACE;IACE,UAAU;IACV,oBAAoB;EACtB;;EAEA;IACE,iBAAiB;EACnB;;EAEA;IACE,iBAAiB;EACnB;AACF","sourcesContent":["@value tablet, desktop from '~@pumpkincare/shared/ui/breakpoints';\n\n.container {\n  display: flex;\n  width: 100%;\n  justify-content: center;\n}\n\n.addButton {\n  margin: 0 0 0 8px;\n}\n\n.declineButton {\n  margin: 0 8px 0 0;\n}\n\n.declineButton,\n.addButton {\n  width: 50%;\n  max-width: 248px;\n}\n\n@media tablet {\n  .container {\n    width: 64%;\n    justify-content: end;\n    margin: auto;\n  }\n}\n\n@media desktop {\n  .container {\n    width: 70%;\n    justify-content: end;\n  }\n\n  .addButton {\n    margin: 0 0 0 8px;\n  }\n\n  .declineButton {\n    margin: 0 8px 0 0;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tablet": "" + ___CSS_LOADER_ICSS_IMPORT_0___.locals["tablet"] + "",
	"desktop": "" + ___CSS_LOADER_ICSS_IMPORT_0___.locals["desktop"] + "",
	"container": "confirm-pep-buttons_container_8d78b582",
	"addButton": "confirm-pep-buttons_addButton_b08171e5",
	"declineButton": "confirm-pep-buttons_declineButton_931225dc"
};
export default ___CSS_LOADER_EXPORT___;
