import { useEffect, useRef } from 'react';

import { checkMarketingAttributionForExpiry } from '@pumpkincare/marketing';
import { getQuotePets, useQuote, useQuotesActiveId } from '@pumpkincare/quotes';

import Routes from '../routes';

function App() {
  useEffect(() => {
    checkMarketingAttributionForExpiry();
  }, []);

  const { data: quoteData, isFetched, isFetching } = useQuote();
  const petsData = getQuotePets(quoteData);
  const { activeId, setActiveId } = useQuotesActiveId();

  /*
   these hydration blocks useful when returning to an existing quote, especially multipet
   this block should happen as high in the app as possible (rather than individually in qf, plan, checkout)
   due to React's context api, this has to happen here and not in app-controller

   only hydrate if there is a quote to load on site load, and not on any quote update
   */
  const shouldHydrateContext = useRef(!!(isFetching && quoteData.id));
  useEffect(() => {
    if (shouldHydrateContext.current && isFetched && petsData.length && !activeId) {
      setActiveId(petsData[petsData.length - 1].id);
    }
  }, [shouldHydrateContext, isFetched, petsData, activeId, setActiveId]);

  return (
    <div
      style={{
        margin: 'auto',
        backgroundColor: 'var(--zeroWhite)',
        fontFamily: 'var(--main-font)',
      }}
    >
      <Routes />
    </div>
  );
}

export default App;
