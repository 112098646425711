import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';

import { useDiscount } from '@pumpkincare/discounts';
import { useTargetState } from '@pumpkincare/shared';
import { Body1, Body2, LoaderButton, TextField } from '@pumpkincare/shared/ui';

import { Paths } from '../app-shell';

import styles from './teams.css';

function Teams() {
  const dispatch = useDispatch();
  const [discountCode, setDiscountCode] = useTargetState('');
  const [hasError, setHasError] = useState(false);

  const { isFetching, refetch } = useDiscount(discountCode, {
    onSuccess: () => dispatch(push(Paths.Intro)),
    onError: () => {
      setHasError(true);
    },
  });

  function handleSubmit(e) {
    e.preventDefault();

    if (hasError) {
      dispatch(push(Paths.Intro));
    } else {
      refetch();
    }
  }

  function cleanErrorStatus() {
    if (hasError) {
      setDiscountCode('');
      setHasError(false);
    }
  }

  return (
    <div className={styles.mainContainer}>
      <div className={styles.contentBlock}>
        <h1 className={styles.headerH1}>Welcome to Pumpkin!</h1>

        <Body2 className={styles.headerBody1}>
          We're excited to help your pet get the best veterinary care possible.
        </Body2>

        <Body2 isBold className={styles.headerMessage}>
          Enter your code below and enjoy your discount!
        </Body2>

        <form onSubmit={handleSubmit}>
          <TextField
            autoFocus
            value={discountCode}
            classes={{ container: styles.textField }}
            label='Enter Code'
            aria-label='discount count'
            error={{
              errorMessage: hasError
                ? 'You have entered an invalid code. Discount may not be applied when you create your plan.'
                : '',
            }}
            onFocus={cleanErrorStatus}
            onChange={setDiscountCode}
          />

          <LoaderButton
            color='primary'
            classes={{ root: styles.headerButton }}
            isLoading={isFetching}
            type='submit'
          >
            {hasError ? 'Continue' : 'Create My Plan'}
          </LoaderButton>
        </form>

        <Body1>*Note: Discounts will be applied at checkout.</Body1>
      </div>
    </div>
  );
}

export default Teams;
