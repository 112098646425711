import classNames from 'classnames';

import { Body2, ButtonStyles, Typography } from '@pumpkincare/shared/ui';

import styles from './plan-whats-covered.css';

import favIcon from './favicon-benji-blue.svg';

function PlanWhatsCovered({ coverageDetails, onCompareClick }) {
  return (
    <div className={styles.container}>
      <h2 className={Typography.h2}>What’s Covered</h2>
      <h5 className={classNames(styles.subtitle, Typography.h5)}>
        {coverageDetails.coveredSubheader}
      </h5>

      <div className={styles.iconsWrapper}>
        <div className={styles.iconsSection}>
          {coverageDetails.insurance.map((item, title) => (
            <div key={title} className={styles.iconItem}>
              <div className={styles.iconContainer}>
                <img src={item.imageUrl} alt='' />
              </div>

              <div>
                <Body2 isBold dangerouslySetInnerHTML={{ __html: item.title }} />

                <Body2 dangerouslySetInnerHTML={{ __html: item.copy }} />
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className={styles.borderedIconsWrapper}>
        <div className={styles.iconsSection}>
          {coverageDetails?.unique_insurance?.map((item, title) => (
            <div key={title} className={styles.iconItem}>
              <div className={styles.iconContainer}>
                <img src={item.imageUrl} alt='' />
              </div>

              <div>
                <Body2 isBold dangerouslySetInnerHTML={{ __html: item.title }} />

                <Body2 dangerouslySetInnerHTML={{ __html: item.copy }} />
              </div>
            </div>
          ))}
        </div>

        <div className={styles.compareCTA}>
          <img src={favIcon} alt='' />

          <span className={styles.ctaCopy}>
            Pumpkin plans cover these, but some insurances don’t!
            <span
              className={classNames(styles.link, ButtonStyles.cta)}
              onClick={onCompareClick}
              role='button'
            >
              Compare
              <img
                className={styles.ctaArrow}
                src='/assets/images/iconImages/arrow-right-blue-clues.svg'
                alt=''
              />
            </span>
          </span>
        </div>
      </div>

      <h5 className={classNames(Typography.h5, styles.sectionTitle)}>
        {coverageDetails.vetSubheader}
      </h5>

      <div className={styles.iconsWrapper}>
        <div className={styles.iconsSection}>
          {coverageDetails.vet.map((item, title) => (
            <div key={title} className={styles.iconItem}>
              <div className={styles.iconContainer}>
                <img src={item.imageUrl} alt='' />
              </div>

              <div>
                <Body2 isBold dangerouslySetInnerHTML={{ __html: item.title }} />

                <Body2 dangerouslySetInnerHTML={{ __html: item.copy }} />
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className={styles.borderedIconsWrapper}>
        <div className={styles.iconsSection}>
          {coverageDetails?.unique_vet?.map((item, title) => (
            <div key={title} className={styles.iconItem}>
              <div className={styles.iconContainer}>
                <img src={item.imageUrl} alt='' />
              </div>

              <div>
                <Body2 isBold dangerouslySetInnerHTML={{ __html: item.title }} />

                <Body2 dangerouslySetInnerHTML={{ __html: item.copy }} />
              </div>
            </div>
          ))}
        </div>

        <div className={styles.compareCTA}>
          <img src={favIcon} alt='' />

          <span className={styles.ctaCopy}>
            Pumpkin plans cover these, but some insurances don’t!
            <span
              className={classNames(styles.link, ButtonStyles.cta)}
              onClick={onCompareClick}
              role='button'
            >
              Compare
              <img
                className={styles.ctaArrow}
                src='/assets/images/iconImages/arrow-right-blue-clues.svg'
                alt=''
              />
            </span>
          </span>
        </div>
      </div>
    </div>
  );
}

PlanWhatsCovered.defaultProps = {
  coverageDetails: {
    insurance: [],
    header: '',
    unique_insurance: [],
  },
};

export default PlanWhatsCovered;
