import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';
import { push } from 'connected-react-router';
import { useFlags } from 'launchdarkly-react-client-sdk';
import Store from 'store';

import {
  analyticsIdentify,
  analyticsTrack,
  GAQuoteFlowSubmitPetCount,
  getAnonymousId,
  quoteFlowStartedQuote,
} from '@pumpkincare/analytics';
import { postIdentity } from '@pumpkincare/identity';
import {
  getQuotePets,
  useGoToCheckout,
  useQuote,
  useQuotesActiveId,
  useQuotesIsAddMode,
  validatePet,
} from '@pumpkincare/quotes';
import { IDENTITY_LOCAL_STORAGE_KEY } from '@pumpkincare/shared';
import { Body1, ButtonStyles, LegalBody } from '@pumpkincare/shared/ui';
import { getUserEmail, useUssr } from '@pumpkincare/user';

import { Paths } from '../../../app-shell';
import useQuoteNextStep from '../../utils/use-quote-next-step';
import { NextButton } from '../shared';
import EditPet from './edit-pet';
import useSubmitIntroduction from './use-submit-introduction';

import styles from './introduction.css';

const DEFAULT_ID = new Date().valueOf();

export default function Introduction() {
  const { punks1954UpsellSawToSawIns } = useFlags();
  const { activeId, setActiveId } = useQuotesActiveId(DEFAULT_ID);
  const { isAddMode } = useQuotesIsAddMode();

  const dispatch = useDispatch();
  const { data: quoteData } = useQuote();
  const quotePets = getQuotePets(quoteData);

  const goToCheckout = useGoToCheckout(Paths.Checkout);
  const nextStep = useQuoteNextStep();

  const { isSubmitting, submitIntroduction } = useSubmitIntroduction();

  const {
    data: { userEmail },
  } = useUssr(data => ({ userEmail: getUserEmail(data) }));

  const [currentPet, setCurrentPet] = useState(
    quotePets.find(pet => pet.id === activeId) ||
      (!isAddMode && quotePets[0]) || {
        id: activeId,
        petName: '',
        petBreedSpecies: '',
      }
  );

  const hasPetNameAndSpecies = currentPet.petName && currentPet.petBreedSpecies;

  const disableFields =
    punks1954UpsellSawToSawIns &&
    !isAddMode &&
    quoteData.isRegistered &&
    hasPetNameAndSpecies;

  const headerClassName = classNames({
    [styles.quoteFlowHeader]: isAddMode,
    [styles.quoteFlowHeaderAdd]: !isAddMode,
  });

  useEffect(() => {
    analyticsTrack(quoteFlowStartedQuote());
  }, []);

  useEffect(() => {
    if (userEmail) {
      const id = new Date().valueOf();
      setActiveId(id);
      analyticsIdentify(
        {
          MULTIPET: 'True',
          ADDPETLATERDAY: 'True',
          email: userEmail,
        },
        getAnonymousId()
      );
    }
  }, [userEmail, setActiveId]);

  function handleNext() {
    GAQuoteFlowSubmitPetCount({ totalCount: quotePets.length + 1 });

    Promise.resolve(
      Store.get(IDENTITY_LOCAL_STORAGE_KEY) ||
        postIdentity().then(({ id }) => {
          Store.set(IDENTITY_LOCAL_STORAGE_KEY, id);
          return id;
        })
    )
      .then(identityId =>
        submitIntroduction({
          identityId,
          trackingId: getAnonymousId(),
          pet: currentPet,
        })
      )
      .then(() => {
        dispatch(push(nextStep));
      });
  }

  function handlePetChange(pet) {
    setCurrentPet(pet);
  }

  function handleGoCheckoutClick() {
    goToCheckout();
  }

  return (
    <>
      <h3 className={headerClassName}>
        {isAddMode
          ? 'The more, the healthier - let’s add another pet!'
          : 'Let’s create your pet’s plan!'}
      </h3>

      <div className={styles.content}>
        {isAddMode ? (
          <Body1 className={styles.multiPetTitle}>
            Don’t worry if you don’t see it on the next page - the 10% multi-pet
            discount for all additional pets is applied at checkout!
          </Body1>
        ) : null}

        <EditPet
          pet={currentPet}
          onChange={handlePetChange}
          isDisabled={disableFields}
        />

        {isAddMode ? null : (
          <div className={styles.badgeSection}>
            <img
              alt=''
              src='/assets/images/star-badge.png'
              className={styles.badgeIcon}
            />

            <LegalBody className={styles.badgeText}>
              If you have multiple fur kids, fill in just one of their names for now.
              After you’ve created your first pet’s plan, you can add more pets and
              save 10% on each additional plan!
            </LegalBody>
          </div>
        )}

        <NextButton
          disabled={!validatePet(currentPet)}
          data-testid={'next-button'}
          onClick={handleNext}
          isLoading={isSubmitting}
        />

        {isAddMode ? (
          <>
            <button
              className={classNames(styles.skipButton, ButtonStyles.tertiary)}
              onClick={handleGoCheckoutClick}
              data-testid='go-back-button'
            >
              Skip and proceed to checkout
            </button>
          </>
        ) : null}
      </div>
    </>
  );
}
