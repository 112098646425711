import PropTypes from 'prop-types';

import { LoaderButton } from '@pumpkincare/shared/ui';

import styles from './next-button.css';

function NextButton({ text, ...rest }) {
  return (
    <LoaderButton
      color='primary'
      classes={{ root: styles.root }}
      data-testid={'next-button'}
      {...rest}
    >
      {text}
    </LoaderButton>
  );
}

NextButton.defaultProps = {
  tabIndex: 0,
  autoFocus: false,
  text: 'Next',
};

NextButton.propTypes = {
  disabled: PropTypes.bool,
  isLoading: PropTypes.bool,
  style: PropTypes.object,
  onClick: PropTypes.func,
  tabIndex: PropTypes.number,
  autoFocus: PropTypes.bool,
  text: PropTypes.string,
};

export default NextButton;
