import { Fragment } from 'react';

function TermsStatic({ waitingPeriods }) {
  const RESIDENTS = [
    {
      header: 'For Delaware residents only.',
      body: `There is a ${waitingPeriods.illness.period} day waiting period for illnesses. This waiting period may be met if you and your pet meet certain requirements. For more information, please read the Notice to Delaware Residents.`,
    },
    {
      header: 'For Louisiana residents only.',
      body: `There is a ${waitingPeriods.illness.period} day waiting period for illnesses. This waiting period may be met if you and your pet meet certain requirements. For more information, please read the Notice to Louisiana Residents.`,
    },
    {
      header: 'For Maine residents only.',
      body: `There is a ${waitingPeriods.illness.period} day waiting period for illnesses. This waiting period may be met if you and your pet meet certain requirements. For more information, please read the Notice to Maine Residents.`,
    },
    {
      header: 'For Mississippi residents only.',
      body: `There is a ${waitingPeriods.illness.period} day waiting period for illnesses. This waiting period may be met if you and your pet meet certain requirements. For more information, please read the Notice to Mississippi Residents.`,
    },
    {
      header: 'For Montana residents only.',
      body: `There is a ${waitingPeriods.illness.period} day waiting period for illnesses. This waiting period may be met if you and your pet meet certain requirements. For more information, please read the Notice to Montana Residents.`,
    },
    {
      header: 'For Nebraska residents only.',
      body: `There is a ${waitingPeriods.illness.period} day waiting period for illnesses. This waiting period may be met if you and your pet meet certain requirements. For more information, please read the Notice to Nebraska Residents.`,
    },
    {
      header: 'For New Hampshire residents only.',
      body: `There is a ${waitingPeriods.illness.period} day waiting period for illnesses. This waiting period may be met if you and your pet meet certain requirements. For more information, please read the Notice to New Hampshire Residents.`,
    },
    {
      header: 'For Washington residents only.',
      body: `There is a ${waitingPeriods.illness.period} day waiting period for illnesses. This waiting period may be met if you and your pet meet certain requirements. For more information, please read the Notice to Washington Residents.`,
    },
  ];

  return (
    <>
      {/* Protect Copy */}
      <h3>Pumpkin Pet Insurance Policy Terms</h3>
      <p>
        For your convenience, we’ll automatically issue you a new plan every 12
        months unless you contact us to cancel. We’ll use your current payment method
        and notify you in advance of any changes to your plan or premium.
      </p>
      <b>Pre-existing conditions are not covered.</b>
      <p>
        Conditions that show symptoms before the plan’s effective date or during a
        waiting period will be considered pre-existing and ineligible for coverage,
        even if they haven’t been formally diagnosed or treated by a veterinarian.
        However, we will no longer consider a condition to be pre-existing if it has
        been cured and free of symptoms and treatment for 180 days, with the
        exception of knee and ligament conditions. If a knee or ligament condition
        occurs before the coverage effective date or during a waiting period, any
        future ones won’t be covered.
      </p>
      <b>The information provided must be accurate.</b>
      <p>
        Coverage may be cancelled if important information about a pet is
        intentionally not disclosed or is misrepresented.
      </p>
      <p>
        <b>Pet Residence Restriction:</b> Your pet must reside with you at the
        primary address listed on the declarations page. It is your responsibility to
        notify us of any change in address. A change in your primary address may
        result in a change to coverage availability and rates.
      </p>
      <p>
        <b>Pet parents must be 18 or older to purchase this insurance.</b>
      </p>
      <p>
        <b>Waiting periods apply in the first plan period.</b>
      </p>
      <p>
        Pumpkin Pet Insurance Coverage begins on the 15th day after the policy
        effective date listed on policy. Coverage for Pumpkin Preventive Essentials
        starts on the policy effective date. The waiting period begins on the first
        effective date of the applicable coverage. Conditions that show symptoms
        before the plan’s effective date or during a waiting period will be
        considered pre-existing and ineligible for coverage, even if they haven’t
        been formally diagnosed or treated by a veterinarian. However, we will no
        longer consider a condition to be pre-existing if it has been cured and free
        of symptoms and treatment for 180 days, with the exception of knee and
        ligament conditions. If a knee or ligament condition occurs before the
        coverage effective date or during a waiting period, any future ones won’t be
        covered.
      </p>
      <b>Authorization to access medical information and records.</b>
      <p>
        By enrolling, you authorize us to access all medical information and records
        that we need to assess your pet’s health either prior to or when you submit a
        claim and you agree to provide us with any missing medical information and
        records. You must also provide proof of identity of your pet if we request
        it.
      </p>
      <b>Rules apply for certain discounts.</b>
      <p>
        If you are eligible for a discount due to your employment status, group
        membership or affiliation you must inform us of any change in eligibility
        status.
      </p>

      {RESIDENTS.map(({ header, body }) => (
        <Fragment key={header}>
          <b>{header}</b>
          <p>{body}</p>
        </Fragment>
      ))}

      {/* Fraud Notice */}
      <b>Fraud Notice</b>
      <p>
        <b>NOTICE TO APPLICANTS:</b> Any person who knowingly presents a false or
        fraudulent claim for payment of a loss or benefit or knowingly presents false
        information in an application for insurance is guilty of a crime and may be
        subject to fines and confinement in prison.
      </p>
      <p>
        <b>NOTICE TO CALIFORNIA APPLICANTS:</b> Any person who knowingly presents
        false or fraudulent information to obtain or amend insurance coverage or to
        make a claim for the payment of a loss is guilty of a crime and may be
        subject to fines and confinement in state prison.
      </p>
      <p>
        <b>NOTICE TO CONNECTICUT APPLICANTS:</b> Concealment, fraud. This entire
        policy shall be void if, whether before or after a loss, the insured has
        willfully concealed or misrepresented any material fact or circumstance
        concerning this insurance or the subject thereof, or the interest of the
        insured therein, or in case of any fraud or false swearing by the insured
        relating thereto.
      </p>
      <p>
        <b>NOTICE TO COLORADO APPLICANTS:</b> It is unlawful to knowingly provide
        false, incomplete, or misleading facts or information to an insurance company
        for the purpose of defrauding or attempting to defraud the company. Penalties
        may include imprisonment, fines, denial of insurance and civil damages. Any
        insurance company or agent of an insurance company who knowingly provides
        false, incomplete, or misleading facts or information to a policy holder or
        claimant for the purpose of defrauding or attempting to defraud the policy
        holder or claimant with regard to a settlement or award payable from
        insurance proceeds shall be reported to the Colorado Division of Insurance
        within the Department of Regulatory Agencies.
      </p>
      <p>
        <b>NOTICE TO DISTRICT OF COLUMBIA APPLICANTS:</b> It is a crime to provide
        false or misleading information to an insurer for the purpose of defrauding
        the insurer or any other person. Penalties include imprisonment and/or fines.
        In addition, an insurer may deny insurance benefits if false information
        material related to a claim was provided by the applicant.
      </p>
      <p>
        <b>NOTICE TO FLORIDA APPLICANTS:</b> Any person who knowingly and with intent
        to injure, defraud, or deceive any insurer files a statement of claim or an
        application containing any false, incomplete, or misleading information is
        guilty of a felony of the third degree.
      </p>
      <p>
        <b>NOTICE TO HAWAII APPLICANTS:</b> For your protection, Hawaii law requires
        you to be informed that presenting a fraudulent claim for payment of a loss
        or benefit is a crime punishable by fines or imprisonment, or both.
      </p>
      <p>
        <b>NOTICE TO ILLINOIS APPLICANTS:</b> A person who knowingly makes any false
        or fraudulent statement or presentation in or with reference to any
        application, or for the purpose of obtaining any fee, commission, money, or
        benefit from or in any company transacting business under this article,
        commits a Class A misdemeanor.
      </p>
      <p>
        <b>NOTICE TO KANSAS APPLICANTS:</b> Fraudulent insurance act means an act
        committed by any person who, knowingly and with intent to defraud, presents,
        causes to be presented or prepares with knowledge or belief that it will be
        presented to or by an insurer, purported insurer, broker or any agent
        thereof, any written, electronic, electronic impulse, facsimile, magnetic,
        oral, or telephonic communication statement as part of, or in support of, an
        application for the issuance of, or the rating of an insurance policy for
        personal or commercial insurance, or a claim for payment or other benefit
        pursuant to an insurance policy for commercial or personal insurance which
        such person knows to contain materially false information concerning any fact
        material thereto; or conceals, for the purpose of misleading, information
        concerning any fact material thereto.
      </p>
      <p>
        <b>NOTICE TO KENTUCKY APPLICANTS:</b> Any person who knowingly and with
        intent to defraud any insurance company or other person files an application
        for insurance containing any materially false information or conceals for the
        purpose of misleading, information concerning any fact material thereto
        commits a fraudulent insurance act which is a crime.
      </p>
      <p>
        <b>NOTICE TO LOUISIANA APPLICANTS:</b> Any person who knowingly presents a
        false or fraudulent claim for payment of a loss or benefit or knowingly
        presents false information in an application for insurance is guilty of a
        crime and may be subject to fines and confinement in prison.
      </p>
      <p>
        <b>NOTICE TO MAINE APPLICANTS:</b> It is a crime to knowingly provide false,
        incomplete or misleading information to an insurance company for the purpose
        of defrauding the company. Penalties may include imprisonment, fines or a
        denial of insurance benefits.
      </p>
      <p>
        <b>NOTICE TO MARYLAND APPLICANTS:</b> Any person who knowingly or willfully
        presents a false or fraudulent claim for payment of a loss or benefit or who
        knowingly or willfully presents false information in an application for
        insurance is guilty of a crime and may be subject to fines and confinement in
        prison.
      </p>
      <p>
        <b>NOTICE TO NEW MEXICO APPLICANTS:</b> Any person who knowingly presents a
        false or fraudulent claim for payment for payment of a loss or benefit or
        knowingly presents false information in an application for insurance is
        guilty of a crime and may be subject to civil fines and criminal penalties.
      </p>
      <p>
        <b>NOTICE TO NEW HAMPSHIRE RESIDENTS:</b> Any person who, with a purpose to
        injure, defraud, or deceive any insurance company, files a statement of claim
        containing any false, incomplete, or misleading information is subject to
        prosecution and punishment for insurance fraud, as provided in RSA 638:20.
      </p>
      <p>
        <b>NOTICE TO NEW JERSEY APPLICANTS:</b> Any person who includes any false or
        misleading information on an application for an insurance policy is subject
        to criminal and civil penalties.
      </p>
      <p>
        <b>NOTICE TO NEW YORK APPLICANTS:</b> Any person who knowingly and with
        intent to defraud an insurance company or other person files an application
        for insurance or statement of claim containing any materially false
        information, or conceals for the purpose of misleading, information
        concerning any fact material thereto, commits a fraudulent insurance act,
        which is a crime and shall also be subject to a civil penalty not to exceed
        five thousand dollars and the stated value of the claim for each such
        violation.
      </p>
      <p>
        <b>NOTICE TO OHIO APPLICANTS:</b> Any person who, with intent to defraud or
        knowing that he is facilitating a fraud against an insurer, submits an
        application or files a claim containing a false or deceptive statement is
        guilty of insurance fraud.
      </p>
      <p>
        <b>NOTICE TO OKLAHOMA APPLICANTS:</b> WARNING: Any person who knowingly, and
        with intent to injure, defraud or deceive any insurer makes any claim for the
        proceeds of an insurance policy containing any false, incomplete or
        misleading information is guilty of a felony.
      </p>
      <p>
        <b>NOTICE TO PENNSYLVANIA APPLICANTS:</b> Any person who knowingly and with
        intent to defraud any insurance company or other person files an application
        for insurance or statement of claim containing any materially false
        information or conceals for the purpose of misleading, information concerning
        any fact material thereto commits a fraudulent insurance act, which is a
        crime and subjects such person to criminal and civil penalties.
      </p>
      <p>
        <b>NOTICE TO TENNESSEE APPLICANTS:</b> It is a crime to knowingly provide
        false, incomplete or misleading information to an insurance company for the
        purpose of defrauding the company. Penalties include imprisonment, fines and
        denial of insurance benefits.
      </p>
      <p>
        <b>NOTICE TO VIRGINIA APPLICANTS:</b> It is a crime to knowingly provide
        false incomplete or misleading information to an insurance company for the
        purpose of defrauding the company. Penalties may include imprisonment, fines,
        or denial of insurance benefits.
      </p>
      <p>
        <b>NOTICE TO WASHINGTON APPLICANTS:</b> It is a crime to knowingly provide
        false, incomplete, or misleading information to an insurance company for the
        purpose of defrauding the company. Penalties include imprisonment, fines, and
        denial of insurance benefits.
      </p>
      <br />
    </>
  );
}

export default TermsStatic;
