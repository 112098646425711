/*
  User can then set up flags to ignore users with a country of 'some-string' to stop them from being in the roll-out
  Limitation is multi-variate / sequence of roll-out tests, but that is not a use case we intend to have
 */
function formatLaunchDarklyUser(identityId, attributes) {
  const user = identityId ? { key: identityId } : { anonymous: true };

  if (attributes) {
    user.country = attributes;
  } else if (window.Cypress) {
    user.country = 'e2e';
  }

  return user;
}

export default formatLaunchDarklyUser;
