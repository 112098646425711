import { useFlags } from 'launchdarkly-react-client-sdk';

import { Body1, Superscript1, Typography } from '@pumpkincare/shared/ui';

import styles from './money-back-guarantee.module.css';

import moneyGuarantee15 from './15-money-back-guarantee-seal.png';
import moneyGuarantee30 from './30-money-back-guarantee-seal.png';

function MoneyBackGuarantee({ policyState, imgOnly }) {
  const { moneyBackOverridePermanent } = useFlags();

  // Get money-back value from flags. Should be either "15" or "30".
  const moneyBackValue =
    policyState in moneyBackOverridePermanent
      ? moneyBackOverridePermanent[policyState]
      : '30';
  const moneyBackSeal =
    moneyBackValue === '15' ? moneyGuarantee15 : moneyGuarantee30;

  function handleSupscriptClick() {
    setTimeout(() => {
      window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' });
    }, 100);
  }

  if (moneyBackValue === '0') return null;

  if (imgOnly) {
    return (
      <img
        alt='money back guarantee icon'
        src={moneyBackSeal}
        className={styles.imgOnlySize}
      />
    );
  }
  return (
    <>
      <div className={styles.container}>
        <img
          alt='money back guarantee icon'
          className={styles.sealIcon}
          src={moneyBackSeal}
        />

        <div className={styles.content}>
          <h3 className={(Typography.h3, styles.title)}>
            Signing up is stress-free with our money-back guarantee.
          </h3>

          <Body1 className={styles.subTitle}>
            Not digging Pumpkin in the first {moneyBackValue} days? No worries,
            cancel and get a refund.
            <Superscript1
              role='button'
              className={styles.supScript}
              onClick={handleSupscriptClick}
            >
              *
            </Superscript1>
          </Body1>
        </div>
      </div>

      <div className={styles.wave} />
    </>
  );
}
export default MoneyBackGuarantee;
