// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".content_content_2b00271b {\n  display: flex;\n  flex-direction: column;\n\n  justify-content: center;\n  align-items: center;\n\n  width: 100%;\n  margin: 0 auto;\n  padding: 0;\n}", "",{"version":3,"sources":["webpack://src/quotes/view/shared/wrapper/component/content/quote-flow-component-content.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;;EAEtB,uBAAuB;EACvB,mBAAmB;;EAEnB,WAAW;EACX,cAAc;EACd,UAAU;AACZ","sourcesContent":[".content {\n  display: flex;\n  flex-direction: column;\n\n  justify-content: center;\n  align-items: center;\n\n  width: 100%;\n  margin: 0 auto;\n  padding: 0;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"content": "content_content_2b00271b"
};
export default ___CSS_LOADER_EXPORT___;
