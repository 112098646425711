import { useState } from 'react';
import { useQueryClient } from 'react-query';
import PropTypes from 'prop-types';

import { PLAN_QUERY } from '@pumpkincare/plans';
import {
  getQuoteId,
  getQuotePolicyZipCode,
  QUOTE_QUERY,
  useMutateQuoteVet,
  useQuote,
} from '@pumpkincare/quotes';
import { Body2, ButtonStyles, LoaderButton, Modal } from '@pumpkincare/shared/ui';
import { VetClinic } from '@pumpkincare/vets/ui';

import styles from './vet-edit-modal.css';

function VetEditModal({ onClose, icon, petName }) {
  const { mutateAsync: mutateQuoteVet, isLoading: iMutatingQuoteVet } =
    useMutateQuoteVet();

  const { data: quoteData } = useQuote();

  const policyZipcode = getQuotePolicyZipCode(quoteData);

  const queryClient = useQueryClient();
  const quoteId = getQuoteId(quoteData);

  const [vetLocal, setVetLocal] = useState({});

  function handleVetClinicChange(data) {
    setVetLocal(data?.existing || {});
  }

  function handleUpdateClick() {
    return mutateQuoteVet({
      payload: { vet_id: vetLocal.id },
    }).then(() => {
      onClose();
      queryClient.invalidateQueries([PLAN_QUERY, quoteId]);
      queryClient.invalidateQueries([QUOTE_QUERY, quoteId]);
    });
  }

  return (
    <Modal onClose={() => onClose(true)} disabled={iMutatingQuoteVet} small>
      <div className={styles.content}>
        <div>
          {icon ? <img src={icon} alt='' className={styles.topIcon} /> : null}

          <h4 className={styles.modaTitle}>Does {petName} have a vet?</h4>

          <VetClinic
            onChange={handleVetClinicChange}
            selectedVet={Object.keys(vetLocal)?.length ? vetLocal : {}}
            label={'Type to search for your vet hospital'}
            zipcode={policyZipcode}
            classes={{ paper: styles.paper }}
          />

          {Object.keys(vetLocal).length ? (
            <div className={styles.coverageMessage}>
              <Body2 isBold>🎉 Pumpkin covers care from your vet!</Body2>
              <Body2>
                In fact, we cover care from any licensed vet in the US or CAN.
              </Body2>
            </div>
          ) : null}
        </div>
        <div className={styles.footer}>
          <LoaderButton
            classes={{ root: styles.button }}
            color={'primary'}
            disabled={!Object.keys(vetLocal).length}
            isLoading={iMutatingQuoteVet}
            onClick={handleUpdateClick}
          >
            Submit & View Plan
          </LoaderButton>

          <button onClick={() => onClose(false)} className={ButtonStyles.cta}>
            Skip, I don’t have a primary vet
          </button>
        </div>
      </div>
    </Modal>
  );
}

VetEditModal.defaultProps = {
  onClose: () => {},
  icon: '',
  zipCode: '',
  petName: '',
};

VetEditModal.prototype = {
  onClose: PropTypes.func,
  icon: PropTypes.string,
  zipCode: PropTypes.string,
  petName: PropTypes.string,
};
export default VetEditModal;
