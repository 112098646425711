import { useLocation } from 'react-router-dom';
import { useFlags } from 'launchdarkly-react-client-sdk';

import { useQuote } from '@pumpkincare/quotes';
import { getIsLoggedIn } from '@pumpkincare/shared';

import { Paths } from '../../app-shell';

export default function useQuoteNextStep() {
  const { punks1954UpsellSawToSawIns } = useFlags();
  const { pathname } = useLocation();
  const { data: quoteData } = useQuote();
  const hasMultiplePets = quoteData.pets.length > 1;
  const isLoggedIn = getIsLoggedIn();

  let nextPathname;

  const canRouteToPlanSelection = punks1954UpsellSawToSawIns
    ? quoteData.isRegistered || isLoggedIn
    : hasMultiplePets;

  switch (pathname) {
    case Paths.Intro:
      nextPathname = Paths.PetInfo;
      break;

    case Paths.PetInfo:
      nextPathname = canRouteToPlanSelection
        ? Paths.PlanSelection
        : Paths.PetQuestions;
      break;

    case Paths.PetQuestions:
      if (quoteData.isRegistered || isLoggedIn) {
        nextPathname = Paths.PlanSelection;
      } else {
        nextPathname = Paths.Register;
      }
      break;

    case Paths.Register:
      nextPathname = Paths.PlanSelection;
      break;

    case Paths.EmbarkHello:
      nextPathname = Paths.Intro;
      break;

    default:
      nextPathname = Paths.Intro;
      break;
  }

  return nextPathname;
}
