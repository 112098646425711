// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".register_register_d22f5f24 {\n  box-sizing: border-box;\n  margin-bottom: 0;\n}\n\n.register_howUseLink_2a93fa24 {\n  font-style: normal;\n  text-decoration-line: underline;\n  padding-left: 5px;\n  color: var(--bluesCluesBlue);\n  cursor: pointer;\n}\n\n.register_howUse_ad58c505 {\n  margin-top: 8px;\n  display: flex;\n}\n\n\n.register_headerText_58a24c16{\n  text-align: center;\n  margin-bottom: 8px;\n}\n\n.register_headerTitle_c2d80e4e{\n  line-height: 32px;\n}\n\n.register_howUseBlurb_86ff1093 {\n  margin: 30px auto 0;\n  text-align: left;\n  font-style: normal;\n}\n\n.register_howUseWrapper_c66a674a {\n  width: 100%;\n}\n\n.register_logIn_5d4cf411 {\n  text-decoration: none;\n}\n\n.register_error_06b7b62a {\n  color: var(--rowlfRed);\n}\n\n.register_textField_de48b71a {\n  margin-bottom: 18px;\n}\n\n", "",{"version":3,"sources":["webpack://src/quotes/view/register/register.css"],"names":[],"mappings":"AAAA;EACE,sBAAsB;EACtB,gBAAgB;AAClB;;AAEA;EACE,kBAAkB;EAClB,+BAA+B;EAC/B,iBAAiB;EACjB,4BAA4B;EAC5B,eAAe;AACjB;;AAEA;EACE,eAAe;EACf,aAAa;AACf;;;AAGA;EACE,kBAAkB;EAClB,kBAAkB;AACpB;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,mBAAmB;EACnB,gBAAgB;EAChB,kBAAkB;AACpB;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,qBAAqB;AACvB;;AAEA;EACE,sBAAsB;AACxB;;AAEA;EACE,mBAAmB;AACrB","sourcesContent":[".register {\n  box-sizing: border-box;\n  margin-bottom: 0;\n}\n\n.howUseLink {\n  font-style: normal;\n  text-decoration-line: underline;\n  padding-left: 5px;\n  color: var(--bluesCluesBlue);\n  cursor: pointer;\n}\n\n.howUse {\n  margin-top: 8px;\n  display: flex;\n}\n\n\n.headerText{\n  text-align: center;\n  margin-bottom: 8px;\n}\n\n.headerTitle{\n  line-height: 32px;\n}\n\n.howUseBlurb {\n  margin: 30px auto 0;\n  text-align: left;\n  font-style: normal;\n}\n\n.howUseWrapper {\n  width: 100%;\n}\n\n.logIn {\n  text-decoration: none;\n}\n\n.error {\n  color: var(--rowlfRed);\n}\n\n.textField {\n  margin-bottom: 18px;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"register": "register_register_d22f5f24",
	"howUseLink": "register_howUseLink_2a93fa24",
	"howUse": "register_howUse_ad58c505",
	"headerText": "register_headerText_58a24c16",
	"headerTitle": "register_headerTitle_c2d80e4e",
	"howUseBlurb": "register_howUseBlurb_86ff1093",
	"howUseWrapper": "register_howUseWrapper_c66a674a",
	"logIn": "register_logIn_5d4cf411",
	"error": "register_error_06b7b62a",
	"textField": "register_textField_de48b71a"
};
export default ___CSS_LOADER_EXPORT___;
