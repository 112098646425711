import { useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import {
  Body1,
  Body2,
  LegalBody,
  Modal,
  Superscript2,
} from '@pumpkincare/shared/ui';

import styles from './insurance-modal.module.css';

function InsuranceModal({ modalData, classes }) {
  const [isModalOpen, setModalOpen] = useState(false);
  const modalFooterClass = classNames(styles.modalFooter, {
    [styles.modalFooterItems]: modalData.footer?.items,
  });

  function handleSupScriptClick() {
    setModalOpen(false);

    setTimeout(() => {
      window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' });
    }, 100);
  }

  return (
    <>
      <div
        onClick={() => setModalOpen(true)}
        tabIndex='0'
        className={styles.infoIcon}
        role='dialog'
      >
        <img
          src='/assets/images/iconImages/icon_info.svg'
          alt=''
          id='modal-icon'
          className={classNames(styles.modalIcon, classes.modalIcon)}
        />
      </div>

      {isModalOpen ? (
        <Modal
          onClose={() => setModalOpen(false)}
          classes={{
            modalDialog: styles.modalDialog,
            container: styles.modalContainer,
          }}
        >
          <div className={styles.modalBody}>
            <span className={classNames(styles.modalTitle, classes.modalTitle)}>
              {modalData.title}
            </span>

            {modalData.sectionItems?.map((item, index) => (
              <div className={styles.modalSection} key={item.title}>
                <Body1 isBold> {item.title}</Body1>

                <Body2>
                  {item.copy}
                  {index === 1 && modalData.hasSup ? (
                    <Superscript2
                      className={styles.supScript}
                      onClick={handleSupScriptClick}
                    >
                      1
                    </Superscript2>
                  ) : null}
                </Body2>
              </div>
            ))}

            <div className={modalFooterClass}>
              <div className={styles.modalImageWrapper}>
                <img
                  src={modalData.footer?.img}
                  className={styles.modalImage}
                  alt=''
                />
              </div>

              <div>
                <div className={styles.modalSection}>
                  {modalData.footer?.items ? (
                    <>
                      {modalData.footer?.items.map((item, index) => (
                        <div className={styles.footerItems} key={index}>
                          <LegalBody isBold>{item.title}</LegalBody>
                          <LegalBody>{item.copy}</LegalBody>
                        </div>
                      ))}
                    </>
                  ) : (
                    <>
                      <Body1 isBold> {modalData.footer?.title}</Body1>
                      <Body2>{modalData.footer?.copy}</Body2>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </Modal>
      ) : null}
    </>
  );
}

InsuranceModal.propTypes = {
  modalData: PropTypes.shape({
    title: PropTypes.node,
    sectionItems: PropTypes.array,
    hasSup: PropTypes.bool,
    footer: PropTypes.shape({
      title: PropTypes.string,
      copy: PropTypes.string,
    }),
  }),
  classes: PropTypes.shape({
    modalTitle: PropTypes.string,
    modalIcon: PropTypes.string,
  }),
};

InsuranceModal.defaultProps = {
  modalData: {},
  classes: { modalTitle: '', modalIcon: '' },
};

export default InsuranceModal;
