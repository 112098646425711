// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_ICSS_IMPORT_0___ from "-!../../../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[2].use[1]!../../../../../../libs/shared/src/ui/breakpoints.module.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".quote-flow-header_header_c612b912 {\n    margin: 0 0 16px;\n    text-align: center;\n}\n\n@media " + ___CSS_LOADER_ICSS_IMPORT_0___.locals["b-mobile"] + " {\n    /*margin: 40px 0 16px;*/\n}", "",{"version":3,"sources":["webpack://src/quotes/view/quote-flow-header/quote-flow-header.css"],"names":[],"mappings":"AAEA;IACI,gBAAgB;IAChB,kBAAkB;AACtB;;AAEA;IACI,uBAAuB;AAC3B","sourcesContent":["@value b-mobile from '~@pumpkincare/shared/ui/breakpoints';\n\n.header {\n    margin: 0 0 16px;\n    text-align: center;\n}\n\n@media b-mobile {\n    /*margin: 40px 0 16px;*/\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"b-mobile": "" + ___CSS_LOADER_ICSS_IMPORT_0___.locals["b-mobile"] + "",
	"header": "quote-flow-header_header_c612b912"
};
export default ___CSS_LOADER_EXPORT___;
