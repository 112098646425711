import { generateIdentifyMeta } from '@pumpkincare/shared';

import { getQuotesEmail, getQuotesTrackingId } from '../selectors/quotes-selectors';
import { setActiveId as setActiveIdInRedux } from '../state/quotes-ducks';

export default function setCheckoutPetAsActive(id) {
  return function (dispatch, getState) {
    const email = getQuotesEmail(getState());
    const trackingId = getQuotesTrackingId(getState());

    dispatch(
      setActiveIdInRedux(
        id,
        generateIdentifyMeta({ MULTIPET: 'True', email }, trackingId)
      )
    );
  };
}
