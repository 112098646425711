import { generateIdentifyMeta } from '@pumpkincare/shared';

import { setAddANewPet } from '../../quotes';
import { getQuotesEmail, getQuotesTrackingId } from '../../quotes/selectors';

export default function goToAddNewPet(id) {
  return function (dispatch, getState) {
    const state = getState();
    const trackingId = getQuotesTrackingId(state);
    const email = getQuotesEmail(state);

    dispatch(
      setAddANewPet(
        { id, petName: '' },
        generateIdentifyMeta({ MULTIPET: 'True', email }, trackingId)
      )
    );
  };
}
