import { useRef, useState } from 'react';
import classNames from 'classnames';
import { useDebouncedCallback } from 'use-debounce';

import { GAQuoteFlowClickHowWillWeUseThis } from '@pumpkincare/analytics';
import { MC_URL } from '@pumpkincare/config';
import {
  getDiscountId,
  useDiscount,
  validateDiscountByZipCode,
} from '@pumpkincare/discounts';
import {
  getQuoteEmail,
  getQuoteFirstName,
  getQuoteLastName,
  getQuotePolicyZipCode,
  getQuoteVet,
  useQuote,
} from '@pumpkincare/quotes';
import {
  captureException,
  GENERIC_ERROR,
  requestZipCodeValidity,
  useBanners,
  validateEmail,
} from '@pumpkincare/shared';
import {
  LegalBody,
  TextField,
  Typography,
  ZipcodeField,
} from '@pumpkincare/shared/ui';
import { postCheckEmail } from '@pumpkincare/user';
import { VetAttribution } from '@pumpkincare/vets/ui';

import QuoteFlowHeader from '../quote-flow-header';
import { NextButton, QuoteFlowComponentContent } from '../shared';
import useSubmitRegister from './use-submit-register';

import styles from './register.css';

// TODO: https://petlife.atlassian.net/browse/PUNKS-1324
// technically partner is only embark in production, so no user should be coming from /teams with embark code
const discountBandaidStates = {
  HI: ['vet', 'employer', 'partner'],
  MN: ['vet', 'partner'],
  TN: ['vet', 'employer', 'partner'],
  NY: ['vet'],
  FL: ['employer', 'partner'],
};

function Register() {
  const { data: quoteData, isSuccess: isQuoteSuccess } = useQuote();
  const quotePolicyZipCode = getQuotePolicyZipCode(quoteData);
  const quoteEmail = getQuoteEmail(quoteData);
  const quoteFirstName = getQuoteFirstName(quoteData);
  const quoteLastName = getQuoteLastName(quoteData);
  const quoteVet = getQuoteVet(quoteData);

  const { data: discountData } = useDiscount();
  const discountId = getDiscountId(discountData);

  const { addBanner, removeAllBanners } = useBanners();
  const { submit: submitRegister, isLoading } = useSubmitRegister();

  const [firstName, setFirstName] = useState(quoteFirstName ?? '');
  const [lastName, setLastName] = useState(quoteLastName ?? '');
  const [email, setEmail] = useState(quoteEmail ?? '');
  const [policyZipCode, setPolicyZipCode] = useState(quotePolicyZipCode ?? '');
  const vet = useRef(quoteVet);
  const shouldSubmitDiscount = useRef(true);
  const [showHowWeUseInfo, setShowHowWeUseInfo] = useState(false);
  const [emailError, setEmailError] = useState('');
  const [errorMessages, setErrorMessages] = useState({});
  const [canSubmitVet, setCanSubmitVet] = useState(false);
  const [zipDisabled, setZipDisabled] = useState(false);
  const [isZipCodeError, setZipCodeError] = useState(null);

  const isValidEmail = validateEmail(email);
  const isEmailInputValid = !!email && isValidEmail && !emailError;
  const isZipCodeInputValid = !!policyZipCode && isZipCodeError === false;

  const canSubmit =
    firstName &&
    lastName &&
    isEmailInputValid &&
    isZipCodeInputValid &&
    isLoading === false &&
    canSubmitVet;

  const invalidZipError =
    'Hmmm, we can’t find that one. Please enter a valid U.S. zip code.';
  const ineligibleDiscount =
    'Unfortunately, due to state regulations, you are not eligible for a group discount. Don’t worry you are still eligible for coverage.';

  const debouncedValidateEmail = useDebouncedCallback(value => {
    postCheckEmail(value)
      .then(({ exists }) => {
        setEmailError(
          exists ? (
            <span>
              Looks like you already have a Pumpkin account. Please{' '}
              <a href={MC_URL} className={styles.logIn}>
                log in
              </a>{' '}
              and visit the "My Pet's Plan" page to add another pet.
            </span>
          ) : (
            ''
          )
        );
      })
      .catch(() => {
        setEmailError('Unable to verify email');
      });
  }, 500);

  const debouncedValidateZipCode = useDebouncedCallback(value => {
    requestZipCodeValidity(value)
      .then(({ state, valid }) => {
        setZipCodeError(false);

        if (!valid && !state) {
          setErrorMessages(state => ({ ...state, zipcode: invalidZipError }));
        } else if (discountId) {
          validateDiscountByZipCode(discountId, value)
            .then(discount => {
              if (
                discountBandaidStates[state]?.find(
                  override => override === discount.org_type
                )
              ) {
                setErrorMessages(state => ({
                  ...state,
                  zipcode: ineligibleDiscount,
                }));
                shouldSubmitDiscount.current = false;
              } else {
                shouldSubmitDiscount.current = true;
              }
            })
            .catch(error => {
              shouldSubmitDiscount.current = false;

              switch (error.response.status) {
                case 400: {
                  setErrorMessages(state => ({
                    ...state,
                    zipcode: invalidZipError,
                  }));

                  break;
                }
                case 404: {
                  setErrorMessages(state => ({
                    ...state,
                    zipcode: 'Discount not found.',
                  }));

                  break;
                }
                case 422: {
                  setErrorMessages(state => ({
                    ...state,
                    zipcode: ineligibleDiscount,
                  }));

                  break;
                }
                default: {
                  setErrorMessages(state => ({
                    ...state,
                    zipcode: 'Generic error.',
                  }));

                  break;
                }
              }
            });
        } else {
          setErrorMessages(state => ({ ...state, zipcode: null }));
        }
      })
      .catch(() => {
        setZipCodeError(true);
        setErrorMessages(state => ({ ...state, zipcode: invalidZipError }));
      });
  }, 500);

  function handleVetClinicChange(data) {
    vet.current = data;

    setCanSubmitVet(!!vet.current?.vet_name);
  }

  function handleHasVetChange(value) {
    vet.current = {};
    setCanSubmitVet(value ? !!vet.current?.vet_name : true);
  }

  function handleFirstNameChange({ target: { value } }) {
    setFirstName(value);
  }

  function handleLastNameChange({ target: { value } }) {
    setLastName(value);
  }

  function handleEmailChange({ target: { value } }) {
    setEmailError('');
    setEmail(value);

    if (validateEmail(value)) {
      debouncedValidateEmail(value);
    }
  }

  function handleOnBlurEmail({ target: { value } }) {
    if (value) {
      if (value.includes(' ')) {
        setEmailError('Email should not contain spaces.');
      } else if (!validateEmail(value)) {
        setEmailError('Invalid email format.');
      }
    }
  }

  function handleZipCodeChange({ target: { value } }) {
    setZipCodeError(null);
    setPolicyZipCode(value);

    if (value.length === 5) {
      debouncedValidateZipCode(value);
    }
  }

  function handleNextClick() {
    removeAllBanners();
    setZipDisabled(true);

    submitRegister({
      email: email.toLowerCase(),
      firstName,
      lastName,
      policyZipCode,
      vet: vet.current,
      shouldSubmitDiscount: shouldSubmitDiscount.current,
    }).catch(err => {
      captureException(err);

      addBanner(GENERIC_ERROR);
    });
  }

  function handleShowHowWeUseInfo(e) {
    e.preventDefault();

    GAQuoteFlowClickHowWillWeUseThis();

    setShowHowWeUseInfo(!showHowWeUseInfo);
  }

  return isQuoteSuccess ? (
    <>
      <QuoteFlowHeader className={styles.headerTitle}>
        One last thing! Let’s get your info, human.
      </QuoteFlowHeader>

      <QuoteFlowComponentContent className={styles.register}>
        <LegalBody className={styles.headerText}>
          This info can’t be edited at checkout, so make sure you enter it
          prrrfectly!
        </LegalBody>

        <TextField
          label={'Your First Name'}
          id={'first-name'}
          onChange={handleFirstNameChange}
          value={firstName}
          data-testid={'first-name-input'}
          classes={{ container: styles.textField }}
          required
          autoFocus
        />

        <TextField
          label={'Your Last Name'}
          id={'last-name'}
          aria-label={'Your Last Name'}
          onChange={handleLastNameChange}
          value={lastName}
          data-testid={'last-name-input'}
          classes={{ container: styles.textField }}
          required
        />

        <TextField
          label={'Email Address'}
          id={'email'}
          onChange={handleEmailChange}
          onBlur={handleOnBlurEmail}
          error={{
            hasError: !(isEmailInputValid || email === ''),
            errorMessage: emailError,
          }}
          value={email}
          data-testid={'email-input'}
          classes={{ container: styles.textField }}
          required
        />

        <ZipcodeField
          label={'Home Zip Code'}
          id={'zipCode'}
          placeholder={'Home Zip Code'}
          onChange={handleZipCodeChange}
          readOnly={zipDisabled}
          value={policyZipCode}
          error={{
            hasError: !(isZipCodeInputValid || policyZipCode === ''),
          }}
          classes={{ container: styles.textField }}
          data-testid={'zipcode-input'}
          required
        />

        {Object.values(errorMessages).map(errMsg => {
          return errMsg ? (
            <p
              key={errMsg}
              className={classNames(Typography.legalBody, styles.error)}
            >
              {errMsg}
            </p>
          ) : null;
        })}

        <VetAttribution
          disabled={!isZipCodeInputValid}
          zipcode={policyZipCode}
          selectedVet={vet.current}
          onVetClinicChange={handleVetClinicChange}
          onHasVetChange={handleHasVetChange}
        />

        <div className={styles.howUseWrapper}>
          <span className={styles.howUse}>
            <img alt='info' src='/assets/images/info.svg' />

            <span
              onClick={handleShowHowWeUseInfo}
              data-event='Click How will we use this?'
              data-category='Quote Flow'
              className={classNames(Typography.legalBody, styles.howUseLink)}
            >
              How will we use this?
            </span>
          </span>

          {showHowWeUseInfo ? (
            <p className={classNames(Typography.legalBody, styles.howUseBlurb)}>
              Sharing your contact information allows us to provide you with a quote
              based on your location. It also allows us to retrieve a past quote for
              you if you decide you want to purchase at a later date.
            </p>
          ) : null}
        </div>
      </QuoteFlowComponentContent>

      <NextButton
        disabled={!canSubmit}
        onClick={handleNextClick}
        isLoading={isLoading}
        style={{ marginTop: '24px' }}
      />
    </>
  ) : null;
}

export default Register;
