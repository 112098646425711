// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".next-button_root_344f86bc {\n  width: 100%;\n  margin: 40px 0 0;\n}\n", "",{"version":3,"sources":["webpack://src/quotes/view/shared/next-button/next-button.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,gBAAgB;AAClB","sourcesContent":[".root {\n  width: 100%;\n  margin: 40px 0 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "next-button_root_344f86bc"
};
export default ___CSS_LOADER_EXPORT___;
