import classNames from 'classnames';

import { getPlanVersion, isPrevent2 } from '@pumpkincare/plans';
import { getQuoteCompletePets, getQuotePets, useQuote } from '@pumpkincare/quotes';
import {
  formatCurrency,
  formatCustomerAgreementParams,
  getIsLoggedIn,
  useBooleanInput,
} from '@pumpkincare/shared';
import {
  Body2,
  Checkbox,
  LegalBody,
  Modal,
  Typography,
} from '@pumpkincare/shared/ui';
import { getUserIsChargedAnnually, useUssr } from '@pumpkincare/user';

import {
  useErrors,
  useErrorsVisibility,
  useIsChargedAnnuallyValue,
  useTotalsValue,
} from '../../../checkout-provider';
import TermsPrevent2 from './terms-prevent-2';
import TermsPrevent3 from './terms-prevent-3';
import TermsStatic from './terms-static';

import styles from './terms.css';

function Terms({ waitingPeriods }) {
  const { data: quoteData } = useQuote();
  const pets = getIsLoggedIn()
    ? getQuotePets(quoteData)
    : getQuoteCompletePets(quoteData);

  const errors = useErrors();
  const [errorsVisibility, setErrorsVisibility] = useErrorsVisibility();
  const [open, setOpen] = useBooleanInput(false);
  const { data: userData } = useUssr();

  const isLoggedUserAnnuallyCharged = getUserIsChargedAnnually(userData);
  const isLocalAnnuallyCharged = useIsChargedAnnuallyValue();

  const isChargedAnnually = getIsLoggedIn()
    ? isLoggedUserAnnuallyCharged
    : isLocalAnnuallyCharged;

  const { grandTotal } = useTotalsValue();
  const total = formatCurrency(grandTotal);

  const isErrorState = errors.current.termsChecked && errorsVisibility;

  function renderPrevent() {
    const petWithPlan = pets.find(pet => pet.plan);

    if (!petWithPlan) {
      return null;
    }

    const urlSearchString = formatCustomerAgreementParams(
      getPlanVersion(petWithPlan.plan)
    );

    if (isPrevent2(petWithPlan.plan)) {
      return <TermsPrevent2 urlSearchString={urlSearchString} />;
    } else {
      return <TermsPrevent3 urlSearchString={urlSearchString} />;
    }
  }

  function handleCheckboxChange() {
    if (errors.current.termsChecked) {
      delete errors.current.termsChecked;
    } else {
      errors.current = {
        ...errors.current,
        termsChecked:
          'Looks like you forgot to agree to the policy terms and fraud notice',
      };
    }

    setErrorsVisibility(false);
  }

  function handleInsuranceLinkClick(e) {
    e.preventDefault();
    setOpen();
  }

  return (
    <>
      <div className={styles.container}>
        <div className={styles.content}>
          <div className={styles.headerSection}>
            <div className={styles.title}>
              <img src='/assets/images/iconImages/thumbtack.svg' alt='' />

              <h4 className={Typography.h4}>Just one more thing, human!</h4>
            </div>

            <div className={styles.titleImageWrapper}>
              <img
                className={styles.termsImage}
                alt='terms'
                src='/assets/images/cartoons/TermsConditions.png'
              />
            </div>
          </div>

          <div>
            <Body2>
              We get it’s not the most wag-worthy read, but these pet insurance
              policy details are important to understand upfront, so give them a
              read.
            </Body2>
          </div>

          <div className={styles.footerSection}>
            <Checkbox
              classes={{ root: styles.checkboxRoot }}
              onChange={handleCheckboxChange}
              label={
                <LegalBody>
                  I have read and accept{' '}
                  <span
                    className={classNames(Typography.link, styles.linkText)}
                    onClick={handleInsuranceLinkClick}
                  >
                    the insurance policy terms and fraud notice.
                  </span>
                </LegalBody>
              }
              data-testid={'accept-terms-checkbox'}
            />
          </div>

          {isErrorState ? (
            <LegalBody className={styles.error}>
              &nbsp;{errors.current.termsChecked}
            </LegalBody>
          ) : null}
        </div>

        <div className={styles.imageWrapper}>
          <img
            className={styles.termsImage}
            alt='terms'
            src='/assets/images/cartoons/TermsConditions.png'
          />
        </div>
      </div>

      <LegalBody className={styles.calloutGromit}>
        By clicking below, you authorize us to deduct {total}/
        {isChargedAnnually ? 'year' : 'month'} from your provided payment method for
        the full payment of service when due.
      </LegalBody>

      {open ? (
        <Modal onClose={setOpen} classes={{ content: styles.modalContainer }}>
          <div className={styles.modalContent}>
            <TermsStatic waitingPeriods={waitingPeriods} />
            {renderPrevent()}
          </div>
        </Modal>
      ) : null}
    </>
  );
}

export default Terms;
