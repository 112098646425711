import { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import classNames from 'classnames';
import { useFlags } from 'launchdarkly-react-client-sdk';
import Store from 'store';

import {
  analyticsIdentify,
  analyticsTrack,
  INITIATE_EXPERIMENT,
} from '@pumpkincare/analytics';
import { getDiscountId, useDiscount } from '@pumpkincare/discounts';
import { canGoToPlanPage } from '@pumpkincare/plans';
import {
  getQuoteActivePetStrict,
  getQuotePets,
  QUOTE_ID_COOKIE_NAME,
  useQuote,
  useQuotesActiveId,
  useQuotesIsAddMode,
} from '@pumpkincare/quotes';
import {
  EMBARK,
  getCookie,
  getIsLoggedIn,
  QUOTE_URL_PARAMS_KEY,
} from '@pumpkincare/shared';
import { ProgressBar } from '@pumpkincare/shared/ui';

import { Paths } from '../../../app-shell';
import { getQuotesPartner } from '../../selectors';
import {
  calculateEmbarkQuoteFlowProgress,
  calculateQuoteFlowProgress,
} from '../../utils/quote-flow-progress-bar-utils';
import { EmbarkHello } from '../embark-hello';
import Introduction from '../introduction';
import PetInfo from '../pet-info';
import PetQuestions from '../pet-questions';
import Register from '../register';
import { QuoteFlowTransition } from '../shared';

import styles from './quote-flow.css';

export function QuoteFlow() {
  const { outageFlag, wp86ABTestingForWordPress } = useFlags();

  const location = useLocation();
  const { pathname } = location;

  /*
   even though cookie change doesn't trigger a life-cycle update, all the other state changes do
   on those lifecycle changes, quoteId can go from null to a value
   hasQuoteOnLoad used to determine heyflow eligibility based on page load conditions
   */
  const quoteId = getCookie(QUOTE_ID_COOKIE_NAME);
  const { current: hasQuoteOnLoad } = useRef(!!quoteId);

  const quotePartner = useSelector(getQuotesPartner);

  const { data: discountData } = useDiscount();
  const discountId = getDiscountId(discountData);

  const { data: quoteData, isFetched } = useQuote();
  const petsData = getQuotePets(quoteData);
  const { activeId } = useQuotesActiveId();
  const { isAddMode } = useQuotesIsAddMode();

  const canRouteToPlan = canGoToPlanPage(quoteData);
  const canRouteToRegister = petsData.some(pet => pet.petGender && pet.petBreedCode);
  const canRouteToPetInfo = petsData.some(pet => pet.petName);
  const quoteParams = Store.get(QUOTE_URL_PARAMS_KEY) || {};
  const isHeyflowFlag =
    wp86ABTestingForWordPress === 'https://get-quote.pumpkin.care/';
  const isLoggedIn = getIsLoggedIn();
  const utmSourceInParams = quoteParams.utm_source || '';
  const isExcludedFromTest = !!(
    isLoggedIn ||
    hasQuoteOnLoad ||
    discountId ||
    utmSourceInParams
  );
  const canRouteToHeyFlow = !isExcludedFromTest && isHeyflowFlag;
  const isAlreadyInHeyflowTest = quotePartner === 'Heyflow Widget Test';

  const isWideComponent = [Paths.EmbarkHello].includes(pathname);
  const isQuoteIntro = [Paths.Intro].includes(pathname);

  useEffect(() => {
    if (wp86ABTestingForWordPress && isQuoteIntro && !isAddMode) {
      analyticsTrack({
        event: INITIATE_EXPERIMENT,
        category: 'Heyflow Widget Test',
        label: isExcludedFromTest
          ? 'c_excluded'
          : isHeyflowFlag || isAlreadyInHeyflowTest
          ? 'b_test'
          : 'a_control',
      });

      analyticsIdentify({
        isHeyflowFlag,
        isLoggedIn,
        isDiscountFlow: !!discountId,
        isNewQuote: !hasQuoteOnLoad,
        isAlreadyInHeyflowTest,
        utmSourceInParams,
      });
    }
  }, [
    wp86ABTestingForWordPress,
    isLoggedIn,
    isAlreadyInHeyflowTest,
    isHeyflowFlag,
    discountId,
    hasQuoteOnLoad,
    utmSourceInParams,
    isExcludedFromTest,
    isQuoteIntro,
    isAddMode,
  ]);

  return outageFlag && outageFlag?.type === 'scheduled' ? (
    <Redirect
      to={{
        pathname: Paths.MaintenanceFrame,
        state: {
          timeStart: outageFlag.start,
          timeEnd: outageFlag.end,
        },
      }}
    />
  ) : (quoteId && isFetched === false) || !wp86ABTestingForWordPress ? null : (
    <div>
      <ProgressBar
        value={
          quotePartner === EMBARK
            ? calculateEmbarkQuoteFlowProgress(pathname)
            : calculateQuoteFlowProgress(pathname)
        }
      />

      <div
        className={classNames(styles.quoteFlow, {
          [styles.wide]: isWideComponent,
        })}
      >
        <QuoteFlowTransition location={location}>
          <Switch location={location}>
            <Route exact path={Paths.QuoteFlow}>
              {canRouteToHeyFlow ? (
                <HeyFlowRedirect />
              ) : canRouteToPlan ? (
                <Redirect to={Paths.PlanSelection} />
              ) : canRouteToRegister ? (
                <Redirect to={Paths.Register} />
              ) : canRouteToPetInfo ? (
                <Redirect to={Paths.PetInfo} />
              ) : (
                <Redirect to={Paths.Intro} />
              )}
            </Route>

            <Route path={Paths.EmbarkHello}>
              <EmbarkHello />
            </Route>

            <Route path={Paths.Intro}>
              {canRouteToHeyFlow ? (
                <HeyFlowRedirect />
              ) : activeId || !quoteId ? (
                <Introduction />
              ) : null}
            </Route>

            <Route path={Paths.PetInfo}>
              {getQuoteActivePetStrict(quoteData)(activeId) ? <PetInfo /> : null}
            </Route>

            <Route path={Paths.PetQuestions}>
              <PetQuestions />
            </Route>

            <Route path={Paths.Register}>
              <Register />
            </Route>

            <Redirect to={Paths.Intro} />
          </Switch>
        </QuoteFlowTransition>
      </div>
    </div>
  );
}

function HeyFlowRedirect() {
  //Getting the params from QUOTE_URL_PARAMS_KEY because at this point we don't have them in the URL
  const quoteParams = Store.get(QUOTE_URL_PARAMS_KEY) || {};

  //Generating the URL params for the HeyFlow from marketing attribution params
  const urlSearchParams = new URLSearchParams(quoteParams).toString();
  const params = urlSearchParams ? `?${urlSearchParams}` : '';

  window.location.replace(`https://get-quote.pumpkin.care/${params}#start`);
}

export default QuoteFlow;
