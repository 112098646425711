import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';
import { push } from 'connected-react-router';
import Store from 'store';

import {
  analyticsTrack,
  INITIATE_EXPERIMENT,
  planSelectionVersion,
  VISITED_PLAN_PAGE,
} from '@pumpkincare/analytics';
import {
  getPlanVersion,
  getProductHasPrevent,
  isPrevent4,
  usePetPlanData,
  usePlan,
  useProductsByState,
} from '@pumpkincare/plans';
import {
  getQuoteActivePet,
  getQuoteIdentityId,
  getQuotePartner,
  getQuotePolicyState,
  getQuotePolicyZipCode,
  getQuoteVetId,
  useGoToCheckout,
  useMutatePetPlan,
  useQuote,
  useQuotesActiveId,
  useQuotesIsAddMode,
  useQuotesPetOrder,
} from '@pumpkincare/quotes';
import {
  getIsLoggedIn,
  IDENTITY_LOCAL_STORAGE_KEY,
  PARTNER_VET_MODAL_PRESENTED,
  SPRIG_SURVEY_PRESENTED,
  useBooleanInput,
} from '@pumpkincare/shared';
import {
  Body1,
  Body2,
  ButtonStyles,
  KeyboardArrowDown,
  LegalBody,
  Modal,
  NotifyMessage,
  SlideOutPanel,
  Typography,
} from '@pumpkincare/shared/ui';
import { getUserId, useUssr } from '@pumpkincare/user';

import { Paths } from '../../../app-shell';
import goToAddNewPet from '../../thunks/go-to-add-new-pet';
import CompareTable from '../compare-table';
import MoneyBackGuarantee from '../money-back-guarantee';
import PepPicker from '../pep-picker/pep-picker';
import Pep3PickerDetails from '../pep3-picker-details';
import Pep4PickerDetails from '../pep4-picker-details';
import PlanHowItWorks from '../plan-how-it-works';
import PlanMidPageNavbar from '../plan-mid-page-navbar';
import PlanNotCovered from '../plan-not-covered';
import PlanStatistics from '../plan-statistics';
import PlanWhatsCovered from '../plan-whats-covered';
import QuoteEditModal from '../quote-edit-modal';
import VetEditModal from '../vet-edit-modal';
import DropdownNav from './dropdown-nav';
import Picker from './picker';
import StickyBar from './sticky-bar';
import WaveDelimiter from './wave-delimiter';

import styles from './ecomm-view-v2.css';

function EcommViewV2({ planPageContent }) {
  const isLoggedIn = getIsLoggedIn();

  const goToCheckout = useGoToCheckout(Paths.Checkout);

  const {
    header: headerText,
    calculator,
    coverageDetails: planCoverageDetails,
    howItWorks,
    whyPetInsurance,
    preExConditions,
    royalty,
    vetVisits,
  } = planPageContent;

  const isPartnerVetModalShown = Store.get(PARTNER_VET_MODAL_PRESENTED);

  const dispatch = useDispatch();
  const { activeId, setActiveId } = useQuotesActiveId();
  const { setNewPetInOrder } = useQuotesPetOrder();
  const { setIsAddMode } = useQuotesIsAddMode();

  const { data: quoteData } = useQuote();
  const identityId =
    getQuoteIdentityId(quoteData) || Store.get(IDENTITY_LOCAL_STORAGE_KEY);
  const activePet = getQuoteActivePet(quoteData)(activeId);
  const policyZipcode = getQuotePolicyZipCode(quoteData);
  const policyState = getQuotePolicyState(quoteData);
  const vetId = getQuoteVetId(quoteData);
  const quotePartner = getQuotePartner(quoteData);

  // forcing to fetch all necessary data here so it's available in cache when we render child components
  const { data: petPlanData } = usePetPlanData({
    petId: activePet?.id,
    identityId,
    vetId,
  });
  const {
    activeValues,
    insurancePicker: { activePrice, recommendations },
  } = petPlanData;

  const { data: userData } = useUssr();
  const userId = getUserId(userData);

  const { data: planData } = usePlan(
    activePet?.petAge,
    activePet?.petBreedSpecies,
    userId,
    vetId
  );
  const versionNumber = getPlanVersion(planData);

  const { data: productsByStateData } = useProductsByState(policyState);
  const productHasPrevent = getProductHasPrevent(productsByStateData);

  const { mutateAsync: mutatePetPlan } = useMutatePetPlan();

  const [isSlideOpen, setSlideOpen] = useState(false);
  const [addAPetScreen, showAddAPetScreen] = useState(false);
  const [stickyCtaVisibility, setStickyCtaVisibility] = useState(false);
  const [sprigSurveyTimeOut, setSprigSurveyTimeout] = useState(false);
  const [isCompareTableOpen, toggleCompareTableOpen] = useBooleanInput(false);
  const [isEditQuoteOpen, toggleEditQuoteOpen] = useBooleanInput(false);
  const [isVetEditOpen, toggleVetEditOpen] = useBooleanInput(
    quotePartner === 'Heyflow Widget Test' &&
      !isPartnerVetModalShown &&
      !Object.keys(quoteData.vet).length
  );

  const postPickerRef = useRef();
  const whatsCoveredRef = useRef();
  const whatsNotCoveredRef = useRef();
  const howItWorksRef = useRef();
  const whyPetInsuranceRef = useRef();

  const {
    copay: { id: copayId },
    annual_limit: { id: annualLimitId },
    deductible: { id: deductibleId },
  } = recommendations || {};
  const addToCartButtonText = 'Select This Plan';

  function trackNavigation(label) {
    analyticsTrack({
      category: 'Navigation',
      event: INITIATE_EXPERIMENT,
      label: label,
    });
  }

  useEffect(() => {
    function leavingPage(e) {
      const mouseY = e.clientY;
      const topValue = 0;
      const hasSurveyPresented = Store.get(SPRIG_SURVEY_PRESENTED);

      const shouldShowSurvey =
        window.Sprig &&
        !hasSurveyPresented &&
        mouseY < topValue &&
        sprigSurveyTimeOut;

      if (shouldShowSurvey) {
        const openedSurvey = window.Sprig.displaySurvey('117788');

        openedSurvey.then(survey => {
          const { success, surveyState } = survey;
          if (success && surveyState === 'ready') {
            Store.set(SPRIG_SURVEY_PRESENTED, true);
            analyticsTrack({
              event: INITIATE_EXPERIMENT,
              category: 'Sprig Popup',
              label: 'Opened',
            });
          }
        });
      }
    }

    window.addEventListener('mouseout', e => leavingPage(e));

    setTimeout(() => {
      setSprigSurveyTimeout(true);
    }, 60000);

    return () => {
      window.removeEventListener('mouseout', e => leavingPage(e));
    };
  }, [sprigSurveyTimeOut]);

  useEffect(() => {
    document.body.style.overflow = isSlideOpen ? 'hidden' : 'unset';

    return function cleanup() {
      document.body.style.overflow = 'unset';
    };
  }, [isSlideOpen]);

  useEffect(() => {
    analyticsTrack({
      category: 'Visited Plan Page',
      event: VISITED_PLAN_PAGE,
      partner: quotePartner,
    });
  }, [quotePartner]);

  useEffect(() => {
    function handleScroll() {
      if (postPickerRef.current) {
        const { offsetTop } = postPickerRef.current;

        if (window.scrollY > offsetTop) {
          setStickyCtaVisibility(true);
        } else {
          setStickyCtaVisibility(false);
        }
      }
    }

    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);
  }, [postPickerRef]);

  // segment analytics for plan page
  useEffect(() => {
    if (annualLimitId && versionNumber) {
      analyticsTrack(
        planSelectionVersion(
          annualLimitId,
          copayId,
          deductibleId,
          versionNumber,
          vetId
        )
      );
    }
  }, [annualLimitId, copayId, deductibleId, versionNumber, vetId]);

  function handleAddToCart() {
    analyticsTrack({
      category: 'Select Plan',
      event: 'Click Select Plan',
      label: 'Select Plan',
    });

    if (isLoggedIn && (!productHasPrevent || activePet.hasPrevent)) {
      goToCheckout();
    } else {
      window.scrollTo(0, 0);
      setSlideOpen(true);
    }
  }

  function handlePreventChange() {
    analyticsTrack({
      event: activePet.hasPrevent
        ? 'Remove prevent from plan'
        : 'Add prevent to plan',
      category: 'Plan Page',
    });

    mutatePetPlan({
      pet: { ...activePet, hasPrevent: !activePet.hasPrevent },
      plan: planData,
    }).then(() => {
      if (isLoggedIn) {
        goToCheckout();
      }
    });
  }

  function handleOnAdd() {
    analyticsTrack({
      category: 'Add Preventive Essentials',
      event: 'Click Add To Plan',
      label: 'Add Preventive Essentials',
    });

    if (productHasPrevent) {
      handlePreventChange();
    } else if (isLoggedIn) {
      goToCheckout();
    } else {
      showAddAPetScreen(true);
    }

    window.scrollTo(0, 0);
  }

  function handleOnDecline() {
    analyticsTrack({
      category: 'Add Preventive Essentials',
      event: 'Click No Thanks',
      label: 'Add Preventive Essentials',
    });

    if (isLoggedIn) {
      goToCheckout();
    } else {
      showAddAPetScreen(true);
    }

    window.scrollTo(0, 0);
  }

  function handleBackDropClose() {
    showAddAPetScreen(false);
    setSlideOpen(false);
  }

  function handleAddPetClick() {
    analyticsTrack({
      category: 'Want to add another pet?',
      event: 'Click Add Another Pet',
      label: 'Want to add another pet?',
    });

    const id = new Date().valueOf();
    setActiveId(id);
    setNewPetInOrder(id);
    setIsAddMode(true);
    dispatch(goToAddNewPet(id));
    dispatch(push(Paths.Intro));
  }

  function handleGoToCheckoutClick() {
    analyticsTrack({
      category: 'Want to add another pet?',
      event: 'Click No Thanks',
      label: 'Want to add another pet?',
    });

    goToCheckout();
  }

  function scrollIntoView(elem) {
    elem.style.scrollMargin = '40px';
    elem.scrollIntoView({
      behavior: 'smooth',
    });
  }

  function closeVetEditModal(sendAnalytics = true) {
    if (sendAnalytics) {
      analyticsTrack({
        event: INITIATE_EXPERIMENT,
        category: 'Vet Modal',
        label: 'Closed Out',
      });
    }
    Store.set(PARTNER_VET_MODAL_PRESENTED, true);
    toggleVetEditOpen();
  }

  return (
    <>
      {isEditQuoteOpen ? <QuoteEditModal onClose={toggleEditQuoteOpen} /> : null}
      {isVetEditOpen ? (
        <VetEditModal
          onClose={closeVetEditModal}
          icon={vetVisits}
          zipCode={policyZipcode}
          petName={activePet?.petName}
        />
      ) : null}

      <StickyBar
        onClick={() => window.scrollTo({ behavior: 'smooth', top: 0 })}
        visibility={stickyCtaVisibility}
      >
        <button
          className={classNames(
            ButtonStyles.primary,
            ButtonStyles.large,
            styles.selectPlanButton
          )}
          onClick={handleAddToCart}
        >
          <b>Select – ${activePrice} / mo</b>
        </button>

        <DropdownNav
          trackNavigation={trackNavigation}
          items={[
            {
              label: 'Top of Page',
              callback: () => window.scrollTo(0, 0),
            },
            {
              label: 'What’s Covered',
              callback: () => scrollIntoView(whatsCoveredRef.current),
            },
            {
              label: 'What’s Not Covered',
              callback: () => scrollIntoView(whatsNotCoveredRef.current),
            },
            {
              label: 'How Pumpkin Works',
              callback: () => scrollIntoView(howItWorksRef.current),
            },
            {
              label: 'Why Pet Insurance?',
              callback: () => scrollIntoView(whyPetInsuranceRef.current),
            },
          ]}
        />
      </StickyBar>

      {isCompareTableOpen ? (
        <Modal onClose={toggleCompareTableOpen}>
          <CompareTable onClose={toggleCompareTableOpen} />

          <div className={styles.compareFooterSection}>
            <button
              onClick={toggleCompareTableOpen}
              className={classNames(ButtonStyles.cta)}
            >
              Back to Plan Details
            </button>
          </div>
        </Modal>
      ) : null}

      <Picker
        buttonText={addToCartButtonText}
        data-testid='select-phi'
        onAddToCart={handleAddToCart}
        headerText={headerText}
      />

      <div ref={postPickerRef} />

      <SlideOutPanel
        classes={{ container: styles.slideOutContainer }}
        show={isSlideOpen}
        onClose={handleBackDropClose}
      >
        <div className={styles.notifyMessage}>
          {activePet.hasPrevent ? (
            <NotifyMessage
              icon={'/assets/images/iconImages/check-scout-navy.svg'}
              message={`${activePet?.petName}'s Preventive Essentials Added to Cart`}
            />
          ) : null}

          {!activePet.hasPrevent && productHasPrevent && !addAPetScreen ? (
            <NotifyMessage
              icon={'/assets/images/iconImages/check-scout-navy.svg'}
              message={`${activePet?.petName}'s Insurance Added to Cart`}
            />
          ) : null}
        </div>

        <div className={styles.slideOutContent}>
          <div className={styles.slideOutBody}>
            {productHasPrevent === false && policyState !== 'ME' ? (
              <div className={styles.unavailable}>
                <img src='/assets/images/illustrations/multipet/map.png' alt='' />

                <div>
                  <Body2 isBold className={styles.pepPack}>
                    Preventive Essentials Pack is not available in your state at this
                    time.
                  </Body2>

                  <LegalBody className={styles.bodyText}>
                    You’ll still get pawesome accident & illness coverage for{' '}
                    {activePet?.petName} with your insurance. You just won’t have the
                    option to get yearly preventive care covered (e.g. vaccines,
                    wellness exam fees, heartworm or fecal tests). Preventive
                    Essentials is not insurance, but an optional wellness package
                    available in some states.
                  </LegalBody>
                </div>
              </div>
            ) : null}

            {!productHasPrevent ||
            ((activePet.hasPrevent || addAPetScreen) && !isLoggedIn) ? (
              <div>
                <img
                  src={'/assets/images/circle-cat-dog.png'}
                  className={styles.topIconDogCat}
                  alt=''
                />

                <div className={styles.newPetBody}>
                  <span
                    className={classNames(Typography.h3, styles.newPetBodyTitle)}
                  >
                    Want to add another pet?
                  </span>

                  <Body1 className={styles.newPetBodyText}>
                    We have a pawsome 10% discount for covering multiple fur babies.
                  </Body1>
                </div>

                <div className={styles.newPetButtonSection}>
                  <button
                    data-testid='add-a-pet'
                    className={classNames(ButtonStyles.primary, styles.btnStyle)}
                    onClick={handleAddPetClick}
                  >
                    Add Another Pet
                  </button>

                  <button
                    data-testid='no-add-pet'
                    className={classNames(ButtonStyles.secondary, styles.btnStyle)}
                    onClick={handleGoToCheckoutClick}
                  >
                    No Thanks - Go to Checkout
                  </button>
                </div>
              </div>
            ) : (
              <>
                <img
                  alt=''
                  src={'/assets/images/iconImages/design-star.png'}
                  className={styles.topIconStar}
                />

                <PepPicker
                  plan={planData}
                  quoteData={quoteData}
                  activePet={activePet}
                  onAdd={handleOnAdd}
                  onRemove={handleOnDecline}
                />

                <div className={styles.blueCircle}>
                  <KeyboardArrowDown className={styles.arrowIcon} />
                </div>

                {isPrevent4(planData) && activePet ? (
                  <Pep4PickerDetails
                    plan={planData}
                    activePet={activePet}
                    onAdd={handleOnAdd}
                    onRemove={handleOnDecline}
                  />
                ) : (
                  <Pep3PickerDetails
                    pet={activePet}
                    onAdd={handleOnAdd}
                    onRemove={handleOnDecline}
                  />
                )}
              </>
            )}
          </div>
        </div>
      </SlideOutPanel>

      <PlanMidPageNavbar
        whatsRef={whatsCoveredRef}
        whatsNotCovRef={whatsNotCoveredRef}
        whyPetInsRef={whyPetInsuranceRef}
        howItWorksRef={howItWorksRef}
        policyState={policyState}
        trackNavigation={trackNavigation}
      />

      <WaveDelimiter benjiBlueToBlueClues />

      <div ref={whatsCoveredRef} />

      <PlanWhatsCovered
        coverageDetails={planCoverageDetails}
        onCompareClick={toggleCompareTableOpen}
      />

      {policyState !== 'ME' ? (
        <>
          <div ref={whatsNotCoveredRef} />

          <PlanNotCovered
            petBreedSpecies={activePet.petBreedSpecies}
            petAge={activePet.petAge}
          />
        </>
      ) : null}

      <div ref={howItWorksRef} />

      <PlanHowItWorks
        howItWorks={howItWorks}
        topIcon={vetVisits}
        petName={activePet.petName}
        calculatorData={calculator}
        values={activeValues}
        vetName={quoteData.vet.vet_name}
      />

      <WaveDelimiter bluesCluesToHuckleberry reverse />

      <div ref={whyPetInsuranceRef} />

      <PlanStatistics
        whyPetInsurance={whyPetInsurance}
        preExConditions={preExConditions}
        petBreedSpecies={activePet.petBreedSpecies}
        state={policyState}
        royaltyData={royalty}
      />

      <MoneyBackGuarantee policyState={policyState} />
    </>
  );
}

export default EcommViewV2;
